import React from 'react';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatIcon from '@mui/icons-material/Chat';
import VideocamIcon from '@mui/icons-material/Videocam';

export default function About({ zoom , setZoom, isManagement, phone }) {

    React.useEffect(() => {
        setZoom(true)
        return () => setZoom(false)
    }, [setZoom])

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
            <Box sx={{textAlign: 'center'}}>
                <Typography variant='h4' sx={{color: '#75ADBE', mt: 5}}>
                    Contacter un {isManagement ? 'consultant' : 'psychologue'}
                </Typography>
                <Typography variant='body1' sx={{maxWidth: 620, margin: 'auto', mt: 5}}>
                    {isManagement ? 
                    "Vous êtes manager et voulez des conseils pour gérer votre équipe ? Vous rencontrez des difficultés lors d'une situation particulière : changement de fonction ou de poste, conflit interpersonnel ou crise au sein de votre équipe,...Nos consultants en management sont à votre écoute." :
                    `Choisissez un psychologue en découvrant son profil. C'est gratuit et anonyme. Vous pouvez contacter un psychologue par téléphone, tchat ou visio*.`}</Typography>
                <Box
                    sx={{
                        backgroundColor: '#EBF2F5',
                        width: '100%',
                        textAlign: 'center',
                        margin: 'auto',
                        justifyContent: 'center',
                        display: phone ? 'block' : 'flex',
                        mt: 3,
                        pt: 3,
                        pb: 5,
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: 260,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: '#FFBDA6',
                            position: 'relative',
                            margin: phone && 'auto',
                            mr: {xl: 10, xs: 'auto'},
                            mt: {xl: 0, xs: 2},
                            pt: 3,
                            pl: 3,
                            pr: 3,
                            pb: 2
                        }}
                    >
                        <PhoneIcon fontSize="large" sx={{ color: 'white'}}/>
                        <Typography color='primary' variant='h5' sx={{mb: 2, mt: 1}}>PAR TÉLÉPHONE</Typography>
                        <Typography color='primary' variant='body1' sx={{width: 220}}>
                            Composez le numéro vert communiqué par votre entreprise et laissez-vous guider.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            maxWidth: 260,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: '#75ADBE',
                            position: 'relative',
                            margin: phone && 'auto',
                            mr: {xl: 10, xs: 'auto'},
                            mt: {xl: 0, xs: 2},
                            pt: 3,
                            pl: 3,
                            pr: 3,
                            pb: 2
                        }}
                    >
                        <ChatIcon fontSize="large" sx={{ color: 'white' }} />
                        <Typography color='primary' variant='h5' sx={{mb: 2, mt: 1}}>PAR TCHAT</Typography>
                        <Typography color='primary' variant='body1' sx={{width: 220}}>
                            Cliquez sur le bouton "Démarrer la consultation par tchat". Saisissez votre numéro vert. La consultation démarre !
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            maxWidth: 260,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative',
                            margin: phone && 'auto',
                            mr: {xl: 0, xs: 'auto'},
                            mt: {xl: 0, xs: 2},
                            pt: 3,
                            pl: 3,
                            pr: 3,
                            pb: 2
                        }}
                    >
                        <VideocamIcon fontSize="large" sx={{color: '#75ADBE'}} />
                        <Typography color='primary' variant='h5' sx={{mb: 2, mt: 1}}>PAR VISIO*</Typography>
                        <Typography color='primary' variant='body1' sx={{width: 220}}>
                            Cliquez sur le bouton "Démarrer la consultation par visio". Saisissez votre numéro vert. La consultation démarre !
                        </Typography>
                    </Box>
                </Box>
                <Typography color='primary' variant='caption'>*Si votre entreprise a souscrit ce service</Typography>
            </Box>
        </Zoom>
    )
}