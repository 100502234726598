import { Snackbar } from '@mui/material';
import React from 'react'
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { setWithExpiry } from '../Security/expiryLocalStorage';

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}

export default function PhoneSnackbar({ open, setOpen }) {

    const handleClose = () => {
        setWithExpiry('PREVENT_PHONE_SNACKBAR', JSON.stringify('true'), 604800000)
        setOpen(false)
    };
    
    return (
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            TransitionComponent={() => TransitionRight}
        >
            <MuiAlert onClose={handleClose} elevation={6} variant="filled" severity={'info'} >
                Pour une meilleur expérience d'utilisation avec votre téléphone/tablette, vous pouvez télécharger notre application Pros Consulte sur votre store.
            </MuiAlert>
        </Snackbar>
    )
}