import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import Zoom from '@mui/material/Zoom';
import { apiFetch } from '../../Security/apiFetch';
import { findImg, TransitionLeft, translateTitle } from '../../helper/utils';
import PdfReader from '../../components/pdfReader';
import ReactAudioPlayer from 'react-audio-player';
import VideoPlayer from '../../components/VideoPlayer';
import Recommendation from './Recommendation';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import { getFavoris } from '../../Security/favorisStorage';
import SurveyComponent from './themeItem.jsx/Survey/Survey';
import RetranscriptionPopup from './RetranscriptionPopup';

export default function Ressource({
    setOpenSnackbar,
    setText,
    setSeverity,
    setTransition,
    setZoom,
    zoom,
    findPosition,
    setOpenBackdrop,
    navigateRoute,
    setBreadcrumb,
    setTheme,
    handleFavoris,
    setRefresh,
    refreshFavoris,
    phone
}) {
    document.title = 'Espace Salarié - Ressources'
    const [ressource, setRessource] = React.useState(false)
    const [openRetranscription, setOpenRetranscription] = React.useState(false)
    const { id, type, uuid } = useParams()

    React.useEffect(() => {
        (async () => {
            setOpenBackdrop(true)
            setTheme(id)
            setBreadcrumb(['Ressources bien-être', id, type])
            const getRessource = await apiFetch(`/ressources/${uuid}`)
            if (getRessource && getRessource.id) {
                setRessource(getRessource)
                setZoom(true)
            } else {
                setText('Erreur lors de la récupération du ressources !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                if (getRessource === '404') return navigateRoute('/error')
                navigateRoute('/')
                findPosition()
            }
            setOpenBackdrop(false)
        })()
        return () => setZoom(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formatData = (data) => {
        const json = {
            "title": data.titre,
            "showProgressBar": "bottom",
            "showTimerPanel": "top",
            "maxTimeToFinishPage": 30,
            "maxTimeToFinish": 30 * data.questions.length,
            "pages": formatQuestions(data.questions),
            "completedHtml": "<h4>Vous avez <b>{correctAnswers}</b> sur <b>{questionCount}</b> réponses correctes.</h4>",
            "completedHtmlOnCondition": [
                {
                    "expression": "{correctAnswers} == 0",
                    "html": "<h4>Malheureusement, aucune de vos réponses n'est correcte. Veuillez réessayer.</h4>"
                },
                {
                    "expression": "{correctAnswers} == {questionCount}",
                    "html": "<h4>Félicitations ! Vous avez répondu correctement à toutes les questions !</h4>"
                }
            ]
        };
        return json
    }

    const formatQuestions = (questions) => {
        return questions.map((question) => {
            return {
                "elements": [
                    {
                        "type": question.multiple ? "checkbox" : "radiogroup",
                        "name": question.titre,
                        "title": question.titre,
                        "newItem": question.explication,
                        "choicesOrder": "random",
                        "choices": question.choix,
                        "correctAnswer": question.reponses,
                        "enableIf": question.multiple ? `disableMultipleResponse({${question.titre}}) < ${question.reponses.length}` : `{${question.titre}} empty`
                    }
                ]
            }
        })
    }

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
            <div>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: '800ms' }}>
                    <div style={{width: '100%', marginTop: 40, marginBottom: 40, backgroundColor: '#EBF2F5', height: 65}}>
                        <div style={{maxWidth: 1200, display: 'flex', margin: 'auto'}}>
                            <img src={findImg(id)} alt={id} width={100} style={{marginTop: -18}} />
                            <Typography
                                variant='h4'
                                color='primary'
                                sx={{
                                    mt: 1,
                                    ml: 3
                                }}
                            >
                                {translateTitle(id)}
                            </Typography>
                        </div>
                    </div>
                </Zoom>
                <div style={{maxWidth: 1400, margin: 'auto', marginBottom: 30}}>
                    <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
                        <div style={{margin: 'auto', textAlign: 'center', maxWidth: 1000}}>
                            {type !== 'pdf' && <Typography variant='h4' color='primary'>{ressource.titre}</Typography>}
                            <Typography variant={type === 'pdf' ? 'h5' : 'body1'} color='primary'>{ressource.description}</Typography>
                        </div>
                    </Zoom>
                </div>
                <div style={{maxWidth: 1300, margin: 'auto'}}>
                    {ressource && type === 'pdf' && <PdfReader pdfUrl={ressource.fichier} />}
                    {ressource && type === 'podcast' && 
                        <>
                            <ReactAudioPlayer
                                src={ressource.fichier}
                                autoPlay
                                controls
                                style={{
                                    width: '100%',
                                }}
                            />
                            {ressource.retranscription && <Button
                                name='Afficher la retranscription écrite du podcast'
                                variant="text"
                                onClick={() => setOpenRetranscription(true)}
                                sx={{mt: 2, textTransform: 'capitalize', fontSize: 12}}
                            >
                                {!openRetranscription ? 'Afficher la retranscription écrite du podcast' :
                                'Cacher la retranscription écrite du podcast'}
                            </Button>}
                        </>
                    }
                    {ressource && type === 'video' && 
                        <VideoPlayer videoUrl={ressource.fichier} autoPlay={true} />}
                    {ressource && type === 'quiz' && 
                        <SurveyComponent
                            zoom={zoom}
                            data={formatData(ressource)}
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setSeverity={setSeverity}
                            setTransition={setTransition}
                            questions={ressource.questions}
                        />}
                </div>
                <div style={{textAlign: 'center', maxWidth: '1000px', margin: 'auto'}}>
                    <div style={{display: 'inline-flex', marginTop: 15, marginBottom: 5}}>
                        <Typography variant='body2'>Cette ressource vous intéresse ? Ajoutez-là à vos favoris</Typography>
                        {!refreshFavoris ? <IconButton onClick={() => handleFavoris(ressource)} sx={{mt: -1.5}}>
                            {getFavoris() && getFavoris().find((fav) => fav.id === ressource.id) ? <StarIcon color='secondary' /> : <StarOutlineIcon color='secondary' />}
                        </IconButton> : <IconButton sx={{mt: -1.5}}>
                            <StarIcon color='secondary' />
                        </IconButton>}
                    </div>
                </div>
                {ressource && <Recommendation
                    recommandations={ressource.recommandations}
                    zoom={zoom}
                    handleFavoris={handleFavoris}
                    navigateRoute={navigateRoute}
                    setRefresh={setRefresh}
                    refreshFavoris={refreshFavoris}
                    phone={phone}
                />}
                <RetranscriptionPopup open={openRetranscription} handleClose={() => setOpenRetranscription(false)} ressource={ressource} />
            </div>
        </Zoom>
    )
}