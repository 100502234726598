import { Navigate } from 'react-router-dom'
import { getAuth } from './Authentifier'
import { useLocation } from 'react-router-dom';

export const RequireAuth = ({ children, canGoBack }) => {
    const location = useLocation()
    if (
        (!getAuth() && !window.location.pathname.startsWith('/ressources/No%20stress')) ||
        (children.props.identifier === 'ressource' && getAuth() !== 'full' && !window.location.pathname.startsWith('/ressources/No%20stress'))
    ) {
        if ((children.props.isVisio || children.props.isChat) && getAuth()) {
            localStorage.setItem('LAST_URL', JSON.stringify(window.location.pathname))
        }

        return canGoBack ? <Navigate to='/psy' /> : 
            location.state === 'isFromLogin' &&
            location.pathname.startsWith('/ressources/') ? <Navigate to='/ressources' /> : 
            <Navigate to='/login' />
    }
    return children
}