import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import consulterPsy from '../../assets/pc1-image.png'
import conseilManagement from '../../assets/pc2-image.png'
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAuth } from '../../Security/Authentifier';
import logoPc from '../../assets/logoPc.png'
import useMediaQuery from '@mui/material/useMediaQuery';


function LinkTab(props) {

    return (
        <div>
            <Card
                sx={{
                    margin: 2,
                    maxWidth: 250,
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    p: 1,
                    '&:after': {
                        display: 'block;',
                        content: "''",
                        borderBottom: 'solid 3px #FFBDA6;',
                        transform: 'scaleX(0)',
                        transition: 'transform 250ms ease-in-out;',
                    },
                    '&:hover:after': {
                        transform: 'scaleX(1);',
                    }
                }}
                elevation={1}
            >
                <CardActionArea onClick={(event) => {
                    event.preventDefault();
                    if (!props.disableMenu) {
                        if (
                            (localStorage.getItem('LAST_URL') === '"/psy"' && props.href === '/management') || 
                            (localStorage.getItem('LAST_URL') === '"/management"' && props.href === '/psy')
                        ) {
                            props.setRefreshListPsy(!props.refreshListPsy)
                        }
                        props.href !== '/' && props.href !== '/login' && localStorage.setItem('LAST_URL', JSON.stringify(props.href))
                        props.navigateRoute(props.href)
                        props.onClick()
                    }
                }}>
                    <CardMedia
                        component="img"
                        image={props.image === 'Consulter un psychologue' ?
                        consulterPsy :
                        conseilManagement}
                        alt={props.image}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="body1" component="div" style={{textAlign: 'center'}}>
                            {props.image === 'Consulter un psychologue' ?
                                <>Appeler un psychologue<br /><br /></> :
                                "Appeler un consultant en management"
                            }
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
}

const StyledButton = styled(Button)(({ theme }) => ({
    '&:after, &:before': {
        display: 'block;',
        content: "''",
        borderBottom: 'solid 3px #FFBDA6;',
        transform: 'scaleX(0);',
        transition: 'transform 250ms ease-in-out;'
    },
    '&:before': {
        position: 'absolute;',
        left: '0;',
        top: 43,
        width: '100%;',
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    '&:hover:after': {
        transitionDelay: '150ms;'
    },
    '&:hover:after, &:hover:before': { 
        transform: 'scaleX(1);'
    }
}));

function NavBar({ value, handleLogout, navigateRoute, disableMenu, refreshListPsy, setRefreshListPsy }) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const phoneResponsive = useMediaQuery('(min-width:600px)');
    const pages = [
        'Consulter',
        'Conseils bien être',
        !getAuth() ? 'Se connecter' : 'Se déconnecter'
    ];

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (page) => {
        if (!disableMenu) {
            if (page === 'Consulter') {
                return 
            }
            setAnchorElNav(null);
            if (page === 'Se déconnecter') {
                return handleLogout();
            }
            if (page === 'Conseils bien être') {
                return navigateRoute('/ressources');
            }
        }
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleNavigate = (isPsy) => {
        setAnchorElNav(null)
        navigateRoute(isPsy ? '/psy' : '/management')
    }

    return (
        <AppBar position="fixed">
            <Container maxWidth="xxl">
                <Toolbar disableGutters>
                    {/* Mobile */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            disabled={disableMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                                    {page !== 'Consulter' ? <div
                                        style={{
                                            boxShadow:
                                                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                                            borderRadius: 4,
                                            width: '100%',
                                            padding: 10
                                        }}
                                    >
                                        <Typography style={{marginLeft: 6}}>
                                            {page}
                                        </Typography>
                                    </div> :
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Consulter</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{paddingLeft: 30}}>
                                            <Typography
                                                style={{
                                                    height: 35,
                                                    borderBottom: '1px solid #BBB'
                                                }}
                                                onClick={() => handleNavigate(true)}
                                            >
                                                Consulter un psychologue
                                            </Typography>
                                            <Typography
                                                style={{
                                                    marginTop: 10
                                                }}
                                                onClick={() => handleNavigate(false)}
                                            >
                                                Conseil en management
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <img
                        src={logoPc}
                        alt='Pros consulte'
                        style={{
                            width: 230,
                            zIndex: 99999,
                            marginLeft: phoneResponsive ? 50 : Boolean(anchorElNav) ? 25 : -22,
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            if (!disableMenu) {
                                navigateRoute('/')
                            }
                        }}
                    />
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Box>
                            <div style={{borderBottom: value === 0 && '3px solid #FFBDA6', marginTop: value === 2 ? '-2px' : '0px'}}>
                                <StyledButton
                                    endIcon={
                                        !anchorElUser ? 
                                            <KeyboardArrowDownIcon /> :
                                            <KeyboardArrowUpIcon />
                                    }
                                    onClick={handleOpenUserMenu} 
                                    sx={{
                                        fontSize: 17,
                                        color: '#889EAB',
                                        fontWeight: 'bold',
                                        letterSpacing: '2px',
                                        p: '7px 20px',
                                        display: { xs: 'none', md: 'flex' },
                                    }}>
                                    Consulter
                                </StyledButton>
                            </div>
                        </Box>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <Typography
                                variant='body1'
                                sx={{
                                    textAlign: 'center',
                                    letterSpacing: 1
                                }}
                            >
                                Appeler un professionnel
                            </Typography>
                            <div style={{ display: 'flex' }}>
                                <LinkTab
                                    navigateRoute={navigateRoute}
                                    onClick={handleCloseUserMenu} 
                                    href="/psy"
                                    image={'Consulter un psychologue'} 
                                    disableMenu={disableMenu}
                                    setRefreshListPsy={setRefreshListPsy}
                                    refreshListPsy={refreshListPsy}
                                />
                                <LinkTab
                                    navigateRoute={navigateRoute} 
                                    onClick={handleCloseUserMenu} 
                                    label="" href="/management" 
                                    image={'Conseil en management'}
                                    disableMenu={disableMenu}
                                    setRefreshListPsy={setRefreshListPsy}
                                    refreshListPsy={refreshListPsy}
                                />
                            </div>
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        <div style={{borderBottom: value === 2 && '3px solid #FFBDA6', marginTop: value === 0 ? '-1px' : '0px'}}>
                            <StyledButton
                                sx={{
                                    color: '#889EAB',
                                    fontWeight: 'bold',
                                    letterSpacing: '2px',
                                    textDecoration: 'none',
                                    fontFamily: 'Gothic',
                                    p: '7px 20px',
                                    fontSize: 17,
                                }}
                                component="a"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (!disableMenu) {
                                        handleCloseNavMenu()
                                        localStorage.setItem('LAST_URL', JSON.stringify('/ressources'))
                                        navigateRoute('/ressources')
                                    }
                                }}
                            >
                                CONSEILS BIEN-ÊTRE
                            </StyledButton>
                        </div>
                        <div>
                            <StyledButton
                                sx={{
                                    color: '#889EAB',
                                    fontWeight: 'bold',
                                    letterSpacing: '2px',
                                    textDecoration: 'none',
                                    fontFamily: 'Gothic',
                                    p: '7px 20px',
                                    fontSize: 17,
                                }}
                                component="a"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (!disableMenu) {
                                        window.open('https://www.pros-consulte.com/blog/', '_blank')
                                    }
                                }}
                            >
                                BLOG
                            </StyledButton>
                        </div>
                    </Box>
                    {(!getAuth()) ?
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            sx={{
                                float: 'right',
                                letterSpacing: 1,
                                textTransform: 'none',
                                fontWeight: 'bold',
                                fontSize: 16,
                                display: { xs: 'none', md: 'flex' }
                            }}
                            onClick={() => {
                                if (!disableMenu) {
                                    navigateRoute('/login')
                                }
                            }}
                        >
                            {'S'.toUpperCase() + 'e connecter'}
                        </Button>
                    : <Button
                        variant={'outlined'}
                        color={'secondary'}
                        sx={{
                            float: 'right',
                            letterSpacing: 1,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontSize: 16,
                            display: { xs: 'none', md: 'flex' }
                        }}
                        onClick={handleLogout}
                    >
                        {'S'.toUpperCase() + 'e déconnecter'}
                    </Button>}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavBar;