import React, { useRef } from 'react'
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Carousel from 'react-elastic-carousel'
import "react-multi-carousel/lib/styles.css";
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#01304A',
    },
});

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];



export default function Testimonials({ zoom, setZoom }) {
    const carouselRef = useRef(null);

    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(9);
        }
    };

    React.useEffect(() => {
        setZoom(true)
        return () => setZoom(false)
    }, [setZoom])

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
            <Box sx={{textAlign: 'center', backgroundColor: '#EBF2F5', p: {xl: 7, xs: 1}, mt: {xl: 10.5, xs: 12}}}>
                <Typography color='primary' variant='h5' sx={{mb: 5}}>Témoignages</Typography>
                <Carousel
                    ref={carouselRef}
                    breakPoints={breakPoints}
                    onPrevStart={onPrevStart}
                    onNextStart={onNextStart}
                    disableArrowsOnEnd={false}
                >
                    <Box
                        sx={{
                            width: 240,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='body1'
                            sx={{
                                width: 220,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            Grâce à vous je vais mieux et je peux enfin me tourner vers l'avenir… Merci !
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: 240,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='body1'
                            sx={{
                                width: 220,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            J'ai beaucoup apprécié mes échanges avec votre psychologue, Sophie, très professionnelle !</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: 240,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='body1'
                            sx={{
                                width: 220,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            Heureusement que vous étiez là… les consultations m'ont permis de prendre du recul pour aller mieux.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: 240,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='body1'
                            sx={{
                                width: 220,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            J'avais un problème dans mon équipe, et j'ai trouvé des solutions après avoir appelé l'un de vos consultants. Merci pour votre aide.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: 240,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='body1'
                            sx={{
                                width: 220,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            Je n'avais jamais parlé à un psychologue avant...Je regrette d'avoir attendu si longtemps pour le faire ! Ca m'a vraiment aidée.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: 240,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='body1'
                            sx={{
                                width: 220,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            Maintenant je sais qui appeler si je me sens dépassée par ce qui m'arrive. Merci d'avoir été là quand j'en avais besoin !
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: 240,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='body1'
                            sx={{
                                width: 220,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            Je vais parler du numéro vert à tous mes collègues, car ce service est vraiment génial.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: 240,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='body1'
                            sx={{
                                width: 220,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            Je suis fan de votre appli mobile !
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: 240,
                            height: 240,
                            borderRadius: '70% 70% 70% 0%',
                            backgroundColor: 'white',
                            position: 'relative'
                        }}
                    >
                        <Typography
                            color='primary'
                            variant='body1'
                            sx={{
                                width: 220,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            J'ai un collègue qui va mal, et je pense que je vais lui parler de vous.
                        </Typography>
                    </Box>
                </Carousel>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Typography color='primary' variant='body1' sx={{mt: 5}}>TAUX DE SATISFACTION 2023</Typography>
                    <StyledRating name="read-only" value={4.5} precision={0.5} readOnly sx={{mt: 5, ml: 2}} />
                </Box>
            </Box>
        </Zoom>
    )
}