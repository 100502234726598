import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material'
const { LocalAudioTrack, LocalVideoTrack } = require('twilio-video');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewVisio({ open, handleClose, handleAccept, setConstraints, stream, setStream }) {
    const [error, setError] = React.useState(null)
    const [videoRef, setVideoRef] = React.useState(null)
    const [selectedVideoInput, setSelectedVideoInput] = React.useState('')
    const [selectedAudioInput, setSelectedAudioInput] = React.useState('')
    const [selectedAudioOutput, setSelectedAudioOutput] = React.useState('')
    const [audioInputMedia, setAudioInputMedia] = React.useState([])
    const [videoMedia, setVideoMedia] = React.useState([])
    const [audioOutputMedia, setAudioOutputMedia] = React.useState([])

    React.useEffect(() => {
        if (stream) return;
        const getUserMedia = async () => {
            try {
                const fetchStream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
                setStream(fetchStream)
                const getElements = await navigator.mediaDevices.enumerateDevices()
                const tracks = fetchStream.getTracks()
                getElements && getElements.map((element, index) => {
                    if (element.kind === 'audioinput') {
                        setSelectedAudioInput('Default')
                        return setAudioInputMedia((audioInputMedia) => [...audioInputMedia, element])
                    }
                    if (element.kind === 'audiooutput') {
                        const findAudio = tracks.find(track => track.kind === 'audio')
                        if(findAudio) setSelectedAudioOutput(findAudio.label)
                        return setAudioOutputMedia((audioOutputMedia) => [...audioOutputMedia, element])
                    }
                    if (element.kind === 'videoinput') {
                        const findVideo = tracks.find(track => track.kind === 'video')
                        if(findVideo) setSelectedVideoInput(findVideo.label)
                        return setVideoMedia((videoInputMedia) => [...videoInputMedia, element])
                    }
                    return null
                })
            } catch (e) {
                setError(e);
            }
        };

        getUserMedia();
        return cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stream, error]);

    const cancel = () => {
        if (!stream) return;
        if (stream?.getVideoTracks) {
            stream.getVideoTracks().map(track => track.stop());
        }
        if (stream?.getAudioTracks) {
            stream.getAudioTracks().map(track => track.stop());
        }
        if (stream?.stop) {
            stream.stop();
        }
    };

    const handleChange = async (e) => {
        let fetchStream = null
        if (!e.target.name) return
        if (e.target.name === 'audiooutput') {
            setSelectedAudioOutput(e.target.value)
            await videoRef.setSinkId(audioOutputMedia.find((element) => element.label === selectedAudioOutput).deviceId)
        }
        if (e.target.name === 'videoinput') {
            setSelectedVideoInput(e.target.value)
            const constraints = {
                video: {
                    deviceId: videoMedia.find((element) => element.label === e.target.value).deviceId
                },
                audio: {
                    deviceId: audioInputMedia.find((element) => element.label === selectedAudioInput).deviceId
                },
            }
            fetchStream = await navigator.mediaDevices.getUserMedia(constraints)
            const tracks = fetchStream.getTracks().map(track => track.kind === 'audio'? new LocalAudioTrack(track) : new LocalVideoTrack(track));
            setConstraints(tracks)
            cancel()

            setStream(fetchStream)
        }
        if (e.target.name === 'audioinput') {
            setSelectedAudioInput(e.target.value)
            const constraints = {
                video: {
                    deviceId: videoMedia.find((element) => element.label === selectedVideoInput).deviceId
                },
                audio: {
                    deviceId: audioInputMedia.find((element) => element.label === e.target.value).deviceId
                },
            }
            fetchStream = await navigator.mediaDevices.getUserMedia(constraints)
            const tracks = fetchStream.getTracks().map(track => track.kind === 'audio'? new LocalAudioTrack(track) : new LocalVideoTrack(track));
            setConstraints(tracks)
            cancel()

            setStream(fetchStream)
        }
    }

    const beforeClose = () => {
        cancel()
        handleClose()
    }
    
    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            onClose={beforeClose}
            maxWidth={'lg'}
            fullWidth={true}
            aria-describedby="info-popup-description"
        >
                <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                    Paramètres de Visio
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="info-popup-description" component='p' style={{textAlign: 'center', marginBottom: 30}}>
                        Choisissez vos options vidéo et audio 
                    </DialogContentText>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <video
                                autoPlay
                                ref={video => {
                                    if (video) {
                                        video.srcObject = stream;
                                        setVideoRef(video)
                                    }
                                }}
                                id={"video"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{display: 'block'}}>
                            {audioOutputMedia && <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="open-audiooutput-label">Périphériques audio</InputLabel>
                                <Select
                                    labelId="open-audiooutput-label"
                                    id="open-audiooutput"
                                    value={selectedAudioOutput}
                                    name="audiooutput"
                                    label="Périphériques audio"
                                    onChange={handleChange}
                                >
                                    {audioOutputMedia && audioOutputMedia.map((media) => {
                                        return <MenuItem key={media.deviceId} value={media.label}>{media.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>}<br />
                            {audioInputMedia && <FormControl sx={{ m: 1, width: 300, mt: 5 }}>
                                <InputLabel id="open-audioinput-label">Micro</InputLabel>
                                <Select
                                    labelId="open-audioinput-label"
                                    id="open-audioinput"
                                    value={selectedAudioInput}
                                    name="audioinput"
                                    label="Micro"
                                    onChange={handleChange}
                                >
                                    {audioInputMedia && audioInputMedia.map(((media) => {
                                        return <MenuItem key={media.deviceId} value={media.label}>{media.label}</MenuItem>
                                    }))}
                                </Select>
                            </FormControl>}<br />
                            {videoMedia && <FormControl sx={{ m: 1, width: 300, mt: 5 }}>
                                <InputLabel id="open-videoinput-label">Caméra</InputLabel>
                                <Select
                                    labelId="open-videoinput-label"
                                    id="open-videoinput"
                                    value={selectedVideoInput}
                                    name="videoinput"
                                    label="Caméra"
                                    onChange={handleChange}
                                >
                                    {videoMedia && videoMedia.map(((media) => {
                                        return <MenuItem key={media.deviceId} value={media.label}>{media.label}</MenuItem>
                                    }))}
                                </Select>
                            </FormControl>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAccept}>Continuer</Button>
                </DialogActions>
        </Dialog>
    );
}
