import { useEffect, useMemo, useState } from 'react';
import { Routes, Route } from 'react-router-dom'
import Login from './Scenes/Login';
import MainMenu from './Scenes/MainMenu';
import { routes } from './Security/Routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { NotRequireAuth } from './Security/NotRequireAuth';
import ReactGA from 'react-ga';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const TRACKING_ID = "G-RKY2WWVRSQ"
ReactGA.initialize(TRACKING_ID)

function App() {
    const [value, setValue] = useState(1);
    const [canGoBack, setCanGoBack] = useState(false);
    const theme = useTheme();
    const phone = useMediaQuery(theme.breakpoints.down('sm'));
    const tablet = useMediaQuery(theme.breakpoints.down('md'));
    const provideTheme = useMemo(
        () => createTheme({
            palette: {
                primary: {
                    light: '#024061',
                    main: '#01304A',
                    dark: '#001F2F',
                    contrastText: '#fff',
                },
                secondary: {
                    light: '#FFD7C9',
                    main: '#FFBDA6',
                    dark: '#DD8876B',
                    contrastText: '#000',
                },
                background: {
                    default: "#F8F8F8"
                }
            },
            typography: {
                fontFamily: [
                    'gothic',
                    'Aileron Regular',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(',')
            }
        }),
    [],)

    useEffect(() => {
        findPosition()
        window.addEventListener("popstate", findPosition);
        return () => {
            window.removeEventListener("popstate", findPosition)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findPosition = (e) => {
        switch(true) {
            case window.location.pathname.startsWith('/ressources'):
                return setValue(2)
            case window.location.pathname.startsWith('/management'):
                return setValue(0)
            case window.location.pathname.startsWith('/psy'):
                return setValue(0)
            default:
                return setValue(1)
        }
    }

    return (
        <ThemeProvider theme={provideTheme}>
            <Routes>
                <Route path={'*'} element={<MainMenu canGoBack={canGoBack} phone={phone} tablet={tablet} findPosition={findPosition} value={value} />} />
                <Route path={routes.login} exact element={<NotRequireAuth><Login setCanGoBack={setCanGoBack} /></NotRequireAuth>} />
            </Routes>
        </ThemeProvider>
    );
}

export default App;
