export const getFavoris = () => {
    return JSON.parse(localStorage.getItem('FAVORIS'))
}

export const setFavoris = (ressource) => {
    localStorage.setItem('FAVORIS', JSON.stringify(ressource))
}

export const getPsyFavoris = () => {
    return JSON.parse(localStorage.getItem('PSY_FAVORIS'))
}

export const setPsyFavoris = (psy) => {
    localStorage.setItem('PSY_FAVORIS', JSON.stringify(psy))
}