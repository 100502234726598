import React, { useEffect, useState } from 'react'
import { apiFetch } from '../Security/apiFetch'
import { TransitionLeft } from '../helper/utils';
import About from './PsysItem/About';
import Search from './PsysItem/Search';
import ListPsys from './PsysItem/ListPsys';
import bullBleu from '../assets/home/bulle_droite_haut_gris.png'
import bullRose from '../assets/home/bulle_gauche_bas_rose.png'
import PsyPopup from './PsysItem/PsyPopup';
import ReactGA from 'react-ga';
import Favoris from './PsysItem/Favoris';

export default function Psys({
    setOpenSnackbar,
    setText,
    setSeverity,
    setTransition,
    setZoom,
    zoom,
    findPosition,
    setOpenBackdrop,
    navigateRoute,
    setBreadcrumb,
    handlePsyFavoris,
    refreshFavoris,
    isManagement,
    phone,
    refreshListPsy,
}) {
    const TRACKING_ID = "G-RKY2WWVRSQ"
    ReactGA.initialize(TRACKING_ID)
    document.title = isManagement ? 'Espace Salarié - Consultant' : 'Espace Salarié - Psys'
    const [psys, setPsys] = useState([])
    const [openPopup, setOpenPopup] = useState(false)
    const [specialities, setSpecialities] = useState([])
    const [speciality, setSpeciality] = useState([])
    const [languages, setLanguages] = useState([])
    const [language, setLanguage] = useState([])
    const [consultation, setConsultation] = useState([])
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [isActive, setIsActive] = React.useState([true, false, false])

    useEffect(() => {
        (async () => {
            window.scrollTo(0,0)
            setBreadcrumb(isManagement ? ['Joindre un consultant'] : ['Joindre un psychologue'])
            ReactGA.pageview(window.location.pathname)
            setOpenBackdrop(true)
            const getPsys = await apiFetch(isManagement ? '/pros-valides?eligible_coaching_management=1' : '/pros-valides')
            if (getPsys && getPsys.success) {
                setPsys(getPsys.data)
                setZoom(true)
            } else {
                setText('Erreur lors de la récupération des psys !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigateRoute('/')
                findPosition()
            }
            const getSpecialities = await apiFetch('/plateforme/psychologues/liste-des-specialites')
            if (getSpecialities && getSpecialities.length) {
                setSpecialities(getSpecialities)
            }
            const getLanguages = await apiFetch(
                isManagement ? 
                    '/plateforme/psychologues/liste-des-langues?eligible_coaching_management=1' :
                    '/plateforme/psychologues/liste-des-langues'
                )
            if (getLanguages && getLanguages.length) {
                setLanguages(getLanguages)
            }
            setOpenBackdrop(false)
        })()
        return () => setZoom(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshListPsy])

    const handleSearch = async () => {
        setOpenBackdrop(true)
        let hasParams = ''
        if (name) hasParams += `prenom=${name}&`
        if (code) hasParams += `code_pro=${code}&`
        if (speciality.length) {
            speciality.map((item) => hasParams += `specialites[]=${item.nom}&`)
        }
        if (language.length) {
            language.map((item) => hasParams += `langues[]=${item.abbreviation}&`)
        }
        if (consultation.length) {
            consultation.map((item) => hasParams += `canaux[]=${(item.nom).toLowerCase()}&`)
        }
        ReactGA.event({
            category: 'recherche d\'un psy',
            action: 'Click sur le moteur de recherche',
            label: name,
            value: 4
        })

        let search = ''
        if (isManagement) {
            search = await apiFetch(`/pros-valides?eligible_coaching_management=1&${hasParams}`)
        } else {
            search = await apiFetch(`/pros-valides?${hasParams}`)
        }

        if (search && search.success) {
            setPsys(search.data)
        } else {
            setText('Désolé, une erreur s\'est produite, veuillez réessayer !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            navigateRoute('/')
            findPosition()
        }
        setOpenBackdrop(false)
    }

    const handleClosePopup = () => {
        setIsActive([true, false, false])
        setOpenPopup(false)
    }

    return (
        <div style={{marginBottom: 20}}>
            <About setZoom={setZoom} zoom={zoom} isManagement={isManagement} phone={phone} />
            <Favoris
                setZoom={setZoom}
                zoom={zoom}
                handlePsyFavoris={handlePsyFavoris}
                specialities={specialities}
                setOpenPopup={setOpenPopup}
                refreshFavoris={refreshFavoris}
                navigateRoute={navigateRoute}
                phone={phone}
                setOpenBackdrop={setOpenBackdrop}
            />
            <Search
                setZoom={setZoom}
                zoom={zoom}
                specialities={specialities}
                languages={languages}
                name={name}
                setName={setName}
                code={code}
                setCode={setCode}
                handleSearch={handleSearch}
                setSpeciality={setSpeciality}
                speciality={speciality}
                setLanguage={setLanguage}
                setConsultation={setConsultation}
                language={language}
                consultation={consultation}
                isManagement={isManagement}
                phone={phone}
            />
            {!isManagement ? 
                <img
                    src={bullBleu} 
                    alt='Psychologue Pros Consulte' 
                    width={800}
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 850,
                        zIndex: -9999
                    }}
                /> :
                <img
                    src={bullRose} 
                    alt='Psychologue Pros Consulte' 
                    width={
                        phone ? 
                            300 :
                            800
                    } 
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: phone ? 1500 : 925,
                        zIndex: -9999
                    }}
                />
            }
            <ListPsys
                handlePsyFavoris={handlePsyFavoris}
                specialities={specialities}
                zoom={zoom}
                setZoom={setZoom}
                psys={psys}
                setOpenPopup={setOpenPopup}
                refreshFavoris={refreshFavoris}
                navigateRoute={navigateRoute}
                phone={phone}
            />
            <PsyPopup
                handlePsyFavoris={handlePsyFavoris}
                open={openPopup}
                handleClose={handleClosePopup}
                specialities={specialities}
                refreshFavoris={refreshFavoris}
                setOpenSnackbar={setOpenSnackbar}
                setText={setText}
                setSeverity={setSeverity}
                setTransition={setTransition}
                isManagement={isManagement}
                navigateRoute={navigateRoute}
                setIsActive={setIsActive}
                isActive={isActive}
                phone={phone}
            />
        </div>
    )
}