import React, { useContext } from "react";
import { AppContext } from "../wordl";

function Key({ keyVal, bigKey, disabled }) {
    const { gameOver, onSelectLetter, onDelete, onEnter } = useContext(AppContext);
    
    const selectLetter = () => {
        if (gameOver.gameOver) return;
        if (keyVal === "Valider") {
            onEnter();
        } else if (keyVal === "Supprimer") {
            onDelete();
        } else {
            onSelectLetter(keyVal);
        }
    };
    return (
        <div
            className="key"
            id={bigKey ? "big" : disabled ? "disabled" : ''}
            onClick={selectLetter}
        >
            {keyVal}
        </div>
    );
}

export default Key;