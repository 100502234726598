import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { translateTitle } from '../helper/utils';

export default function UiBreadcrumbs({ breadcrumb, navigateRoute, setTheme, theme }) {
    const [type, setType] = React.useState(false)

    function handleClick(event) {
        event.preventDefault();
    }

    const handleNavigate = (route) => {
        navigateRoute(route)
    }

    const formatHrefBreadcrumb = (route) => {
        if (route === 'pdf' || route === 'video' || route === 'podcast') {
            setType(route)
            return `ressources/${theme}/${route}`
        }
        if (route === 'Ressources bien-être') return '/ressources'
        setTheme(route)
        if (route.endsWith('psychologue')) return '/psy'
        if (route.endsWith('consultant')) return '/management'
        return `/ressources/${route}`
    }

    return (
        <div role="presentation" onClick={handleClick} style={{marginBottom: 20, maxWidth: 1500}}>
            <Breadcrumbs
                separator={
                    <NavigateNextIcon fontSize="small" />
                }
                aria-label="breadcrumb"
                sx={{
                    height: 30,
                    placeItems: 'flex-start',
                    borderBottom: 'none',
                    marginLeft: 10,
                    cursor: 'pointer',
                }}
            >
                <Link underline="hover" color="inherit" onClick={() => handleNavigate('/')}>
                    Mon espace
                </Link>
                {breadcrumb && breadcrumb.length && breadcrumb.map(item => {
                    return (
                        <Link
                            underline="hover"
                            color="inherit"
                            href={`/${item}`}
                            onClick={() => handleNavigate(formatHrefBreadcrumb(item))}
                            key={item}
                        >
                            {translateTitle(item)}
                        </Link>
                    )
                })}
            </Breadcrumbs>
        </div>
    );
}