import React from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import { findIcone, FormatUrl } from '../../helper/utils';
import { useParams } from 'react-router-dom';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import { getFavoris } from '../../Security/favorisStorage';
import ReactGA from 'react-ga';

export default function Recommendation({ 
    zoom,
    recommandations,
    handleFavoris,
    navigateRoute,
    setRefresh,
    refreshFavoris,
    phone
}) {
    const TRACKING_ID = "G-RKY2WWVRSQ"
    ReactGA.initialize(TRACKING_ID)
    
    const { id } = useParams()

    const handleNavigate = (recommendation) => {
        setRefresh(true)
        ReactGA.event({
            category: 'recommendations',
            action: 'Click sur une recommendation',
            label: recommendation.titre,
            value: 2
        })
        setTimeout(() => {
            navigateRoute(`/ressources/${id}/${FormatUrl(recommendation.format)}/${recommendation.id}`)
            setRefresh(false)
        }, 50)
    }

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
            <Paper elevation={3} sx={{maxWidth: 1300, margin: 'auto', textAlign: 'center', mb: 3, pb: 5, pt: 1}}>
                <Typography variant="h6" sx={{mb: 2, mt: 1}}>Pour aller plus loin</Typography>
                <div style={{display: phone ? 'block' : 'flex', justifyContent: 'center'}}>
                    {!!recommandations.length && recommandations.map(recommendation =>{
                        return <div
                        key={recommendation.id}
                        style={{
                            width: 300,
                            margin: phone ? 'auto' : '0px 30px 20px 0px',
                        }}
                        className='ressourceCard'
                    >
                        <Box
                            variant='contained'
                            color='secondary'
                            sx={{
                                width: 300,
                                mr: 5,
                                height: 160,
                                backgroundColor: '#ACCDD8',
                                position: 'relative',
                            }}
                            onClick={() => handleNavigate(recommendation)}
                        >
                             <div
                                style={{
                                    position: 'absolute',
                                    top: 30,
                                    left: 40,
                                    right: 20,
                                }}
                            >
                                <div style={{display: 'flex', flexFlow: 'column wrap', height: 40}}>
                                    <img src={findIcone(recommendation.format)} alt={recommendation.format} width={40} style={{zIndex: 9999}} />
                                    <Typography variant='body2' sx={{mt: 1.2, ml: 1, textAlign: 'right'}}>{recommendation.duree_de_lecture} min</Typography>
                                </div>
                                <Typography color='primary' sx={{fontWeight: 'bold', mb: 3, mt: 2}}>{recommendation.titre}</Typography>
                            </div>
                        </Box>
                        <Box
                            variant='contained'
                            color='secondary'
                            sx={{
                                width: 300,
                                mr: 5,
                                height: 140,
                                position: 'relative',
                            }}
                            onClick={() => handleNavigate(recommendation)}
                        >
                             <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 20,
                                    right: 20,
                                }}
                            >
                                <Typography color='primary' sx={{fontWeight: 'bold', mb: 3, mt: 2}}>{recommendation.description}</Typography>
                            </div>
                        </Box>
                        <Box
                            variant='contained'
                            color='secondary'
                            sx={{
                                width: 300,
                                ml: 1,
                                height: 'auto',
                                position: 'relative',
                            }}
                        >
                            <div style={{display: 'flex'}}>
                                {!refreshFavoris ? <IconButton onClick={() => handleFavoris(recommendation)}>
                                    { getFavoris() && getFavoris().find((fav) => fav.id === recommendation.id) ? <StarIcon color='secondary' /> : <StarOutlineIcon color='secondary' />}
                                </IconButton> : <IconButton>
                                    <StarIcon color='secondary' />
                                </IconButton>}
                                <Typography variant='body2' sx={{mt: 1.2, ml: 1}}>Noté comme favori</Typography>
                            </div>
                        </Box>
                    </div>
                    })}
                </div>
            </Paper>
        </Zoom>
    )
}