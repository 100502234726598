import React from 'react'
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import bullBleu from '../../assets/home/bulle_droite_bas_gris.png'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const reasons = [
    {title: 'Parce que vous avez vécu un traumatisme',
    reason: 'Que ce soit dans le cadre de notre vie personnelle ou professionnelle, nous sommes parfois confrontés à des situations qui peuvent nous choquer : maladie, accident du travail, agression verbale…Le traumatisme n’est pas toujours lié à une situation extrême (mort, terrorisme…) et peut être consécutif à un événement perturbateur qui a un impact particulier sur vous, du fait de votre histoire personnelle par exemple. Une prise en charge rapide par un psychologue peut faire toute la différence, et vous être d’un grand secours.'},
    {title: 'Pour faire le point sur votre vie pro ou perso…tout simplement',
    reason: 'Vous avez besoin d’écoute, mais n’avez pas d’oreille impartiale et patiente dans votre entourage ? Parler à un psychologue peut vous soulager. Son écoute se veut sans jugement et vise à apaiser votre souffrance, vos inquiétudes, vous faire tourner la page sur un événement…et bien plus encore !'},
    {title: 'Pour apprendre de vos échecs ou éviter les relations malsaines que vous entretenez',
    reason: 'Vos échecs personnels s’enchaînent ? Vous retombez souvent dans le même type de schéma amoureux ou professionnel ? Il est peut-être le temps de faire le point avec un psychologue sur ces situations récurrentes qui semblent sans issue, pour vous aider à aller de l’avant.'},
    {title: 'Parce que vous avez des maux physiques inexpliqués',
    reason: 'Maux de ventre, migraines, palpitations, fatigue extrême…Vous avez des maux physiques et votre médecin n’a rien vu d’anormal dans vos résultats ? Peut-être est-ce la manifestation d’un mal-être psychique. Votre corps vous envoie un message et vous avez besoin d’aide pour le décoder !'},
    {title: 'Parce que vous souffrez ou pensez souffrir d’un trouble…et ne parvenez pas à mettre des mots sur vos maux',
    reason: 'Alcoolisme, anorexie, anxiété, bipolarité, dépression…Consulter un psychologue peut servir de déclic, pour vous apaiser et vous reconstruire.'},
    {title: 'Parce que vous avez du mal à gérer votre stress',
    reason: 'Si le stress est une réaction normale et essentielle à notre survie, quand il devient chronique il peut avoir un impact nocif : insomnie, sautes d’humeur, épuisement…Un psychologue peut vous aider à comprendre votre stress pour mieux anticiper vos réactions et les gérer.'},
]

const StyledTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 600,
        padding: '20px 40px',
        boxShadow: '-10px 12px 28px #989898',
        borderRadius: 5,
    },
}));

export default function Reasons({ zoom, setZoom, phone }) {

    React.useEffect(() => {
        setZoom(true)
        return () => setZoom(false)
    }, [setZoom])

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
            <Box>
                <Box sx={{textAlign: 'center', mt: {xl: 20, xs: 5}}}>
                    <Typography color='primary' variant='h5'>6 BONNES RAISONS DE FAIRE APPEL À UN PSYCHOLOGUE</Typography>
                    <Typography
                        color='primary' 
                        variant='body1' 
                        sx={{
                            maxWidth: 800,
                            margin: 'auto',
                            mt: 2,
                            ml: {
                                xl: 'auto',
                                lg: 'auto',
                                md: 'auto',
                                sm: 'auto',
                                xs: 2
                            }
                        }
                    }>
                        Le psychologue est un expert des comportements, des émotions et de la santé mentale. Son métier est d’accompagner les personnes qui souffrent sur le plan psychologique, pour les aider à aller mieux. C’est un professionnel diplômé après au moins 5 ans d’études, déclaré auprès de l’Agence Régionale de Santé de son lieu d’exercice.
                    </Typography>
                    <Grid container spacing={3} sx={{mt: {xl: 10, xs: 5}}}>
                        {reasons.map((reason, i) => {
                            return (
                                <Grid item xs={12} sm={4} key={i}>
                                    <Typography color='primary' sx={{fontWeight: 'bold'}} variant='h5'>{i + 1}</Typography>
                                    <Typography color='primary' sx={{fontWeight: 'bold'}} variant='body1'>{reason.title}</Typography>
                                    {!phone && <StyledTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography variant='h6' color='primary' sx={{mb: 2, fontWeight: 'bold'}}>{reason.title}</Typography>
                                                <Typography variant='body1' sx={{textAlign: 'justify'}}>{reason.reason}</Typography>
                                            </React.Fragment>
                                        }
                                        arrow
                                    >
                                        <IconButton color='primary' sx={{mt: 2, cursor: 'default'}}>
                                            <AddCircleOutlineOutlinedIcon />
                                        </IconButton>
                                    </StyledTooltip>}
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
                <img src={bullBleu} alt='Bienvenue dnas votre espace pros consulte' width={phone ? '100%' : 420} style={{float: 'right', marginTop: -330, marginRight: phone ? 0 : 30}} />
            </Box>
        </Zoom>
    )
}