import addictions from '../../assets/ressource/addictions.png'
import communication from '../../assets/ressource/communication.png'
import emotion from '../../assets/ressource/emotion.png'
import epreuve from '../../assets/ressource/epreuve.png'
import equilibre from '../../assets/ressource/equilibre.png'
import nostress from '../../assets/ressource/nostress.png'
import organisation from '../../assets/ressource/organisation.png'
import psychologie from '../../assets/ressource/psychologie.png'
import travail from '../../assets/ressource/travail.png'
import famille from '../../assets/ressource/famille.png'
import confiance from '../../assets/ressource/confiance.png'
import vieAmoureuse from '../../assets/ressource/vie_amoureuse.png'
import management from '../../assets/ressource/management.png'

export const themes = [
    {title: 'No stress', img: nostress, description: 'Vous êtes sujet au stress ? Vous aimeriez réduire ses effets et vous sentir mieux au quotidien ? Découvrez comment fonctionne le stress pour mieux le maîtriser, et suivez nos conseils pour apprendre à mieux gérer votre stress. Testez des exercices, il y en a forcément un pour vous !'},
    {title: 'Travail', img: travail, description: 'Vous avez du mal à bien faire, ou bien vivre votre travail ? Vous voulez en savoir plus sur les risques psychosociaux ? Nos psychologues du travail décryptent pour vous le monde du travail et vous aident à y voir plus clair.'},
    {title: 'Émotions', img: emotion, description: 'Nos émotions font partie de nous et sont notre élan de vie. Parfois elles nous dépassent, nous bousculent, nous troublent…Comprenez comment fonctionnent les émotions pour apprendre à mieux vivre avec, et parvenir à les maîtriser.'},
    {title: 'Communication', img: communication, description: 'Vous êtes en conflit avec vos collègues ou vos proches et ne savez pas comment en sortir ? Vous avez parfois du mal à vous exprimer, à vous affirmer, à dire non ? Découvrez comment rétablir une communication sereine et apaisée, ou faire passer un message, afin qu’il soit compris.'},
    {title: 'Addiction', img: addictions, description: 'Comprendre les addictions pour pouvoir en sortir. Que l’addiction vous touche directement ou concerne l’un de vos proches, trouvez comment faire face à la dépendance. Des solutions existent et nos psychologues vous accompagnent.'},
    {title: 'Équilibre', img: equilibre, description: 'Vous souhaitez atteindre un meilleur équilibre de vie ? Horaires décalés, problèmes de sommeil, fatigue…Découvrez quelles ressources mobiliser pour atteindre plus de bien-être et rétablir l\'équilibre.'},
    {title: 'Épreuve de vie', img: epreuve, description: 'Vous faites face dans votre vie à une épreuve difficile : deuil, maladie, traumatisme…Nos psychologues vous accompagnent, et vous informent sur les solutions qui existent pour aller mieux. Découvrez leurs conseils pour atteindre la résilience et surmonter les épreuves.'},
    {title: 'Psycho', img: psychologie, description: 'Vous vous intéressez à la psychologie ? Vous souhaitez en savoir plus sur les thérapies, les professionnels qui peuvent vous accompagner ? Nous avons sélectionné pour vous les meilleurs contenus afin de découvrir le monde de la psychologie.'},
    {title: 'Organisation', img: organisation, description: 'Vous êtes débordé, désorganisé, avez tendance à remettre les choses au lendemain ? Découvrez comment mettre de l’ordre dans votre vie, pour être plus efficace, et pouvoir libérer du temps pour ce qui a de l’importance pour vous.'},
    {title: 'Famille', img: famille, description: 'La vie de famille peut parfois être source de préoccupations : éducation des enfants et adolescents, conflits, divorce, problèmes scolaires...Nos experts vous informent et vous donnent des conseils, pour une vie familiale sereine et harmonieuse.'},
    {title: 'Vie amoureuse', img: vieAmoureuse, description: 'Vous vous posez des questions sur votre vie amoureuse ? Qu\'il s\'agisse de tensions dans le couple, de séparation, ou de votre vie sexuelle, trouvez les réponses que vous cherchez grâce à nos psychologues.'},
    {title: 'Confiance en soi', img: confiance, description: 'Vous manquez de confiance en vous, et c\'est un obstacle pour vous dans la vie ? Grâce à nos experts, apprenez à vous connaître et à vous faire confiance, pour vivre mieux. Croire en vos capacités vous permettra d\'atteindre vos objectifs en toute sérénité.'},
    {title: 'Management', img: management   , description: 'Que vous soyez manager débutant ou expérimenté, ou désireux d’en apprendre davantage, découvrez comment renforcer vos pratiques, développer votre potentiel et celui de vos équipes à travers des contenus inspirants et variés, des outils et des conseils concrets délivrés par nos psychologues experts.'},
]