import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    useMediaQuery,
    Typography
} from '@mui/material'
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DescriptionPopup({ open, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="info-popup-description"
        >
                <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                    {open.nom}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="info-popup-description" component='div'>
                        <Typography style={{marginTop: 5}} variant='body1'>{open.description}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
        </Dialog>
    );
}
