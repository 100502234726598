import { logout } from "./Authentifier"

export async function apiFetch(endpoint, options = {}) {
    let response = null

    const classicHeader = {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        ...options
    }

    try {
        response = await fetch(process.env.REACT_APP_BASE_URL + endpoint, classicHeader)
    } catch (e) {
        console.warn(e)
        return
    }

    let responseData = null
    if (response.status === 404) return '404'
    if (response) {
        responseData = response.status !== 204 ? await response.json() : response
    } else {
        return logout()
    }

    return responseData
}
