import React, { useCallback, useEffect, useContext } from "react";
import Key from "./Key";
import { AppContext } from "../wordl";

function Keyboard() {
    const keys1 = ["A", "Z", "E", "R", "T", "Y", "U", "I", "O", "P"];
    const keys2 = ["Q", "S", "D", "F", "G", "H", "J", "K", "L", "M"];
    const keys3 = ["W", "X", "C", "V", "B", "N"];

    const {
        board,
        disabledLetters,
        currAttempt,
        gameOver,
        onSelectLetter,
        onEnter,
        onDelete,
    } = useContext(AppContext);

    const handleKeyboard = useCallback(
        (event) => {
            if (gameOver.gameOver) return;
            if (event.key === "Enter") {
                onEnter();
            } else if (event.key === "Backspace") {
                onDelete();
            } else {
                keys1.forEach((key) => {
                    if (event.key.toLowerCase() === key.toLowerCase()) {
                        onSelectLetter(key);
                    }
                });
                keys2.forEach((key) => {
                    if (event.key.toLowerCase() === key.toLowerCase()) {
                        onSelectLetter(key);
                    }
                });
                keys3.forEach((key) => {
                    if (event.key.toLowerCase() === key.toLowerCase()) {
                        onSelectLetter(key);
                    }
                });
            }
        },
        [currAttempt]
    );
    useEffect(() => {
        document.addEventListener("keydown", handleKeyboard);

        return () => {
            document.removeEventListener("keydown", handleKeyboard);
        };
    }, [handleKeyboard]);

    return (
        <div className="keyboard" onKeyDown={handleKeyboard}>
            <div className="line1">
                {keys1.map((key, i) => {
                    return <Key key={i} keyVal={key} disabled={disabledLetters.includes(key)} />;
                })}
            </div>
            <div className="line2">
                {keys2.map((key, i) => {
                    return <Key key={i} keyVal={key} disabled={disabledLetters.includes(key)} />;
                })}
            </div>
            <div className="line3">
                <Key keyVal={"Supprimer"} bigKey />
                {keys3.map((key, i) => {
                    return <Key key={i} keyVal={key} disabled={disabledLetters.includes(key)} />;
                })}
                <Key keyVal={"Valider"} bigKey />
            </div>
        </div>
    );
}   

export default Keyboard;