import React, { useState, useEffect } from 'react'
import Room from './VisioItem/Room';
import { useParams, useLocation } from 'react-router-dom';
import { apiFetch } from '../Security/apiFetch';
import { TransitionLeft } from '../helper/utils';
import PreviewVisio from './VisioItem/PreviewVisio';
import { getCode, getNum } from '../Security/Authentifier';

export default function Visio({
    setTransition,
    setSeverity,
    setText,
    setOpenSnackbar,
    navigateRoute,
    zoom,
    setZoom,
    setDisableMenu,
}) {
    const [roomConstraints, setRoomConstraints] = useState('');
    const [token, setToken] = useState(null);
    const [openPreviewPopup, setOpenPreviewPopup] = useState(null);
    const [psyIdentity, setPsyIdentity] = useState(null);
    const [constraints, setConstraints] = useState({});
    const [openClosePopup, setOpenClosePopup] = useState(false);
    const [stream, setStream] = React.useState(null)
    const { id } = useParams()
    const location = useLocation()
    document.title = 'Mon Espace - Visio'

    useEffect(() => {
        setOpenPreviewPopup(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startVisio = async () => {
        setOpenPreviewPopup(false)

        const body = new URLSearchParams();
        body.append('numero_vert', getNum())

        const checkNum = await apiFetch('/espace-salarie/authentifier', {
            method: 'POST',
            body: body

        })
        if (checkNum.error === 'Code entreprise requis.') body.append('code_groupe', getCode())
        body.append('code_pro', id)

        const fetchToken = await apiFetch(`/plateforme/visio/demarrer-une-consultation-twilio`, {
            method: 'POST',
            body: body
        })

        if (fetchToken && fetchToken.token_utilisateur) {
            setToken(fetchToken.token_utilisateur);
            setPsyIdentity(fetchToken.pseudo_psychologue)
            setRoomConstraints(constraints)
        } else {
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setText('Désolé, une erreur s\'est produite, veuillez réessayer')
            setOpenSnackbar(true)
            navigateRoute(-1)
        }
    }

    const handleCloseVisio = () => {
        setOpenPreviewPopup(false)
        if (location.state && location.state === 'isFromLogin') {
            return navigateRoute('/psy')
        }
        return navigateRoute(-1)
    }

    return (
        <>
            {token && <Room
                setToken={setToken}
                setText={setText}
                setSeverity={setSeverity}
                setTransition={setTransition}
                setOpenSnackbar={setOpenSnackbar}
                roomConstraints={roomConstraints}
                token={token}
                zoom={zoom}
                setZoom={setZoom}
                psyIdentity={psyIdentity}
                setOpenClosePopup={setOpenClosePopup}
                openClosePopup={openClosePopup}
                navigateRoute={navigateRoute}
                setDisableMenu={setDisableMenu}
                stream={stream}
                location={location}
            />}
            <PreviewVisio
                handleAccept={startVisio}
                handleClose={handleCloseVisio}
                open={openPreviewPopup}
                setConstraints={setConstraints}
                stream={stream}
                setStream={setStream}
            />
        </>
    )
}
