import { Fab, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { styled } from '@mui/material/styles';
import NoCamera from '../../assets/NoCamera.png';

const Participant = ({
    participant,
    isLocal,
    blackScreenIsDisplayed,
    setOpenClosePopup,
    setRemoteMic,
    setRemoteCam,
    remoteCam,
    remoteParticipantStyle,
    remoteMicStyle,
}) => {
    const CssFabButton = styled(Fab)({
        backgroundColor: '#DC3545',
        color: 'white',
        "&:hover": { 
            backgroundColor: "#DC3545",
            color: 'white'
        },
    });

    const [videoTracks, setVideoTracks] = useState([]);
    const [toggleMic, setToggleMic] = useState(false);
    const [toggleCam, setToggleCam] = useState(false);
    const [audioTracks, setAudioTracks] = useState([]);
    const [seconds, setSeconds] = useState(0);

    const videoRef = useRef();
    const audioRef = useRef();

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        setVideoTracks(
            trackpubsToTracks(participant.videoTracks).filter(
                (track) => track.name !== "user-screen"
            )
        );
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((videoTracks) => [...videoTracks, track]);
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
            } else if (track.kind === "user-screen") {
            }
        };

        participant.on("trackSubscribed", trackSubscribed);
        participant.on("trackUnsubscribed", trackUnsubscribed);
        participant.tracks.forEach(publication => {
            publication.on('trackEnabled', () => {
                if (!isLocal) {
                    setRemoteMic(true)
                }
                if (publication.kind === 'video' && !participant.identity.startsWith('anonyme')) {
                    setRemoteCam(true)
                }
            });
            publication.on('trackDisabled', () => {
                if (!isLocal) {
                    setRemoteMic(false)
                }
                if (publication.kind === 'video' && !participant.identity.startsWith('anonyme')) {
                    setRemoteCam(false)
                }
            });
        });

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    }, [participant, isLocal]);

    useEffect(() => {
        const video = document.getElementById('remote-video')
        const participant = document.getElementById('local-participant')
        if (remoteParticipantStyle && remoteMicStyle) {
            video.style.height = 
                `${participant.offsetHeight + remoteParticipantStyle.current.offsetHeight + remoteMicStyle.current.offsetHeight - 5}px`
        }
        
    }, [remoteParticipantStyle, remoteMicStyle])

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks, blackScreenIsDisplayed]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            audioTrack.attach(audioRef.current);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    useEffect(() => {
        let interval = null;
        if (!blackScreenIsDisplayed) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 1000);
        }
        return () => clearInterval(interval);
        
    }, [blackScreenIsDisplayed]);

    const muteMic = () => {
        setToggleMic(!toggleMic)
        !toggleMic ? audioTracks.forEach(track => {
            track.disable();
        }) : audioTracks.forEach(track => {
            track.enable();
        })
    }

    const muteCam = () => {
        setToggleCam(!toggleCam)
        !toggleCam ? videoTracks.forEach(track => {
            track.disable();
        }) : videoTracks.forEach(track => {
            track.enable();
        })
    }

    const beforeLogout = () => {
        setOpenClosePopup(true)
    }

    return (
        <div
            className="participant"
            style={{
                color: 'white'
            }}
            id={isLocal ? 'local-participant' : 'remote-participant'}
        >
            <div
                style={
                    isLocal ? {
                        borderTopRightRadius: 30,
                        overflow: 'hidden',
                        backgroundColor: '#323232'
                    } : 
                    {
                        backgroundColor: '#323232',
                        borderTopLeftRadius: 30,
                        borderBottomLeftRadius: 30,
                    }
                }
            >
                {!remoteCam && !isLocal && 
                    <img
                        src={NoCamera}
                        alt='Psychologue deconnecte'
                        width={325}
                        style={{position: 'absolute',
                        top: '50%',
                        left: '40%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                    }}
                />}
                <video
                    style={
                        isLocal ? 
                        {
                            maxWidth: '100%',
                        } :
                        {}
                    }
                    ref={videoRef} 
                    id={isLocal ? "local-video" : "remote-video"}
                    autoPlay={true}
                />

                {isLocal && 
                <div
                    style={{
                        backgroundColor: '#323232',
                    }}
                >
                    <div style={{position: 'relative'}}>
                        <div style={{marginTop: 5, textAlign: 'center', height: blackScreenIsDisplayed ? 25 : 24.5}}>
                            {!blackScreenIsDisplayed && !!seconds &&
                                <Typography variant='body1'>
                                    {new Date(seconds * 1000).toISOString().substr(11, 8)}
                                </Typography>}
                        </div>
                            
                        <div style={{position: 'absolute', zIndex: 999999999, left: '50%', top: -80}}>
                            <div style={{position: 'relative', left: '-50%'}}>
                                <Fab
                                    color={toggleMic ? '' : 'primary'}
                                    aria-label="mic"
                                    style={{
                                        width: 45,
                                        height: 45,
                                        marginRight: 10
                                    }} 
                                    onClick={muteMic}
                                >
                                    {!toggleMic ? <MicIcon /> : <MicOffIcon />}
                                </Fab>
                                <Fab
                                    color={toggleCam ? '' : "primary"}
                                    aria-label="video"
                                    style={{
                                        width: 45,
                                        height: 45,
                                        marginRight: 10
                                    }} 
                                    onClick={muteCam}
                                >
                                    {!toggleCam ? <VideocamIcon /> : <VideocamOffIcon />}
                                </Fab>
                                <CssFabButton onClick={beforeLogout} aria-label="callEnd" style={{width: 45, height: 45}}>
                                    <CallEndIcon />
                                </CssFabButton>
                            </div>
                        </div>
                            
                    </div>
                </div>}
            </div>

            <audio ref={audioRef} autoPlay={true} />
        </div>
    );
};

export default Participant;
