import React from 'react';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import lock from '../../assets/lock.png'
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { themes } from './listOfTheme';
import { getAuth } from '../../Security/Authentifier';
import { translateTitle } from '../../helper/utils';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 25,
    height: 35,
    borderRadius: '0%',
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        borderRadius: 'none',
    },
}));

export default function RessourcesList({ zoom, navigateRoute, phone }) {
    const list = themes
    return (
        <>
            <Typography variant='h4' style={{color: '#75ADBE', marginBottom: 10}}>Explorer</Typography>
            <Typography variant='body1' sx={{mb: 5}}>Découvrez nos ressources pour améliorer votre bien-être mental.</Typography>
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '600ms' }}>
                <div style={{maxWidth: 1200, display: phone ? 'block' : 'flex', margin: 'auto'}}>
                    {list.length && list.map((item, i) => {
                        return i < 4 && <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            badgeContent={
                                getAuth() !== 'full' && i !== 0  ? <SmallAvatar alt="lock" src={lock} /> : ''
                            }
                            key={i}
                            sx={{ mt: {xl: 0, xs: 6.25}}}
                        >
                            <div
                                key={i}
                                onClick={() => {
                                    navigateRoute(`/ressources/${item.title}`)
                                }}
                                className='ressourceCard'
                                style={{
                                    background: getAuth() === 'full' || i === 0 ? 'none' : 'rgba(159, 161, 162, 0.8)',
                                    marginRight: 35,
                                }}
                            >
                                <img
                                    src={item.img}
                                    alt={item.title}
                                    width={250}
                                    style={{
                                        opacity: getAuth() === 'full' || i === 0 ? 1 : 0.1
                                    }}
                                />
                                <Typography color='primary' style={{textAlign: 'center', maxWidth: 250}}>{item.title}</Typography>
                            </div>
                        </StyledBadge>
                    })}
                </div>
            </Zoom>
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '800ms' }}>
                <div style={{maxWidth: 1400, display: phone ? 'block' : 'flex', margin: 'auto', marginTop: 50}}>
                    {list.length && list.map((item, i) => {
                        return i >= 4 && i <= 8 && <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            badgeContent={
                                getAuth() !== 'full' ? <SmallAvatar alt="lock" src={lock} /> : ''
                            }
                            key={i}
                            sx={{ mt: {xl: 0, xs: 6.25}}}
                        >
                            <div
                                onClick={() => {
                                    navigateRoute(`/ressources/${item.title}`)
                                }}
                                className='ressourceCard'
                                style={{
                                    background: getAuth() === 'full' || i === 0 ? 'none' : 'rgba(159, 161, 162, 0.8)',
                                    marginRight: 35
                                }}
                            >
                                
                                <img
                                    src={item.img}
                                    alt={item.title}
                                    width={250}
                                    style={{
                                        opacity: getAuth() === 'full' || i === 0 ? 1 : 0.1
                                    }}
                                />
                                <Typography color='primary' style={{textAlign: 'center', maxWidth: 250}}>{translateTitle(item.title)}</Typography>
                            </div>
                        </StyledBadge>
                    })}
                </div>
            </Zoom>
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
                <div style={{maxWidth: 1200, display: phone ? 'block' : 'flex', margin: 'auto', marginTop: 50}}>
                    {list.length && list.map((item, i) => {
                        return i >= 9 && <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            badgeContent={
                                getAuth() !== 'full' ? <SmallAvatar alt="lock" src={lock} /> : ''
                            }
                            key={i}
                            sx={{ mt: {xl: 0, xs: 6.25}}}
                        >
                            <div
                                onClick={() => {
                                    navigateRoute(`/ressources/${item.title}`)
                                }}
                                className='ressourceCard'
                                style={{
                                    background: getAuth() === 'full' || i === 0 ? 'none' : 'rgba(159, 161, 162, 0.8)',
                                    marginRight: 35
                                }}
                            >
                                
                                <img
                                    src={item.img}
                                    alt={item.title}
                                    width={250}
                                    style={{
                                        opacity: getAuth() === 'full' || i === 0 ? 1 : 0.1
                                    }}
                                />
                                <Typography color='primary' style={{textAlign: 'center', maxWidth: 250}}>{translateTitle(item.title)}</Typography>
                            </div>
                        </StyledBadge>
                    })}
                </div>
            </Zoom>
        </>
    )
}