import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom'
import Ressources from './Ressources';
import { RequireAuth } from '../Security/RequireAuth';
import { routes } from '../Security/Routes';
import { Routes, Route } from 'react-router-dom'
import Psys from './Psys';
import AlertSnackbar from '../components/AlertSnackbar';
import Wordl from './RessourcesItem/themeItem.jsx/wordl/wordl';
import Theme from './RessourcesItem/Theme';
import Type from './RessourcesItem/Type';
import NavBar from './MainMenuItem/NavBar';
import { logout } from '../Security/Authentifier';
import UiBreadcrumbs from '../components/Breadcrumb';
import Ressource from './RessourcesItem/Ressource';
import { getFavoris, getPsyFavoris, setFavoris, setPsyFavoris } from '../Security/favorisStorage';
import Home from './Home';
import Chat from './Chat';
import Error from './Error';
import Visio from './Visio';
import { useTheme } from '@mui/material/styles';
import PhoneSnackbar from '../components/PhoneSnackbar';
import { getWithExpiry } from '../Security/expiryLocalStorage';

export default function MainMenu({ phone, tablet, findPosition, value, canGoBack }) {
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [openPhoneSnackbar, setOpenPhoneSnackbar] = React.useState(false)
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [refreshListPsy, setRefreshListPsy] = React.useState(false);
    const [transition, setTransition] = React.useState(false)
    const [severity, setSeverity] = React.useState(undefined)
    const [text, setText] = React.useState(false)
    const [theme, setTheme] = React.useState(false)
    const [refresh, setRefresh] = React.useState(false)
    const [refreshFavoris, setRefreshFavoris] = React.useState(false)
    const [disableMenu, setDisableMenu] = React.useState(false)
    const [zoom, setZoom] = React.useState(false)
    const [breadcrumb, setBreadcrumb] = React.useState([])
    const mainTheme = useTheme();
    const fullScreen = useMediaQuery(mainTheme.breakpoints.down('sm'));

    let navigate = useNavigate()

    const handleLogout = () => {
        if (!disableMenu) {
            logout()
        }
    }

    React.useEffect(() => {
        if (fullScreen && !getWithExpiry('PREVENT_PHONE_SNACKBAR')) {
            setOpenPhoneSnackbar(true)
        }
    }, [fullScreen])

    const navigateRoute = (route, state) => {
        if (route !== -1 &&
            route !== '/login' &&
            route !== '/' &&
            route !== '/error'
        ) localStorage.setItem('LAST_URL', JSON.stringify(route))

        navigate(route, state)
        findPosition()
    }

    const handleFavoris = (ressource) => {
        setRefreshFavoris(true)
        if ((!getFavoris()) || (getFavoris() && !getFavoris().find((fav) => fav.id === ressource.id))) {
            setFavoris(getFavoris() ? getFavoris() && [...getFavoris(), ressource] : [ressource])
        } else {
            setFavoris(getFavoris() && getFavoris().filter((fav) => fav.id !== ressource.id))
        }
        setTimeout(() => {
            setRefreshFavoris(false)
        }, 50)
    }

    const handlePsyFavoris = (psy) => {
        setOpenBackdrop(true)
        setRefreshFavoris(true)
        if ((!getPsyFavoris()) || (getPsyFavoris() && !getPsyFavoris().find((code_perso_pro) => code_perso_pro === psy.code_perso_pro))) {
            setPsyFavoris(getPsyFavoris() ? getPsyFavoris() && [...getPsyFavoris(), psy.code_perso_pro] : [psy.code_perso_pro])
        } else {
            setPsyFavoris(getPsyFavoris() && getPsyFavoris().filter((code_perso_pro) => code_perso_pro !== psy.code_perso_pro))
        }
        setTimeout(() => {
            setRefreshFavoris(false)
        }, 50)
    }

    return (
        <div>
            <NavBar
                disableMenu={disableMenu}
                value={value}
                handleLogout={handleLogout}
                navigateRoute={navigateRoute}
                refreshListPsy={refreshListPsy}
                setRefreshListPsy={setRefreshListPsy}
            />
            <div style={{marginTop: 75, maxWidth: '100%'}}>
                {!window.location.pathname.endsWith('/wordle') &&
                !window.location.pathname.startsWith('/chat') &&
                !phone &&
                    <UiBreadcrumbs
                        setTheme={setTheme} 
                        theme={theme} 
                        breadcrumb={breadcrumb} 
                        navigateRoute={navigateRoute} 
                    />}
                <Backdrop style={{zIndex: 999999}} open={openBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AlertSnackbar open={openSnackbar} setOpen={setOpenSnackbar} transition={transition} message={text} severity={severity} />
                <PhoneSnackbar open={openPhoneSnackbar} setOpen={setOpenPhoneSnackbar} />
                <Routes>
                    <Route path={routes.ressources} exact element={
                        <Ressources 
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setSeverity={setSeverity}
                            setTransition={setTransition}
                            setOpenBackdrop={setOpenBackdrop}
                            zoom={zoom}
                            findPosition={findPosition}
                            setZoom={setZoom}
                            navigateRoute={navigateRoute}
                            setBreadcrumb={setBreadcrumb}
                            handleFavoris={handleFavoris}
                            refreshFavoris={refreshFavoris}
                            phone={phone}
                            tablet={tablet}
                        />}
                    />
                    <Route path={routes.home} exact element={
                        <Home
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setSeverity={setSeverity}
                            setTransition={setTransition}
                            setOpenBackdrop={setOpenBackdrop}
                            zoom={zoom}
                            setZoom={setZoom}
                            findPosition={findPosition}
                            navigateRoute={navigateRoute}
                            setBreadcrumb={setBreadcrumb}
                            phone={phone}
                            tablet={tablet}
                        />}
                    />
                    <Route path={routes.psy} exact element={
                        <Psys
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setSeverity={setSeverity}
                            setTransition={setTransition}
                            setOpenBackdrop={setOpenBackdrop}
                            zoom={zoom}
                            setZoom={setZoom}
                            findPosition={findPosition}
                            navigateRoute={navigateRoute}
                            setBreadcrumb={setBreadcrumb}
                            handlePsyFavoris={handlePsyFavoris}
                            refreshFavoris={refreshFavoris}
                            phone={phone}
                            tablet={tablet}
                            refreshListPsy={refreshListPsy}
                        />}
                    />
                    <Route path={routes.management} exact element={
                        <Psys
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setSeverity={setSeverity}
                            setTransition={setTransition}
                            setOpenBackdrop={setOpenBackdrop}
                            zoom={zoom}
                            setZoom={setZoom}
                            findPosition={findPosition}
                            navigateRoute={navigateRoute}
                            setBreadcrumb={setBreadcrumb}
                            handlePsyFavoris={handlePsyFavoris}
                            refreshFavoris={refreshFavoris}
                            isManagement={true}
                            phone={phone}
                            tablet={tablet}
                            refreshListPsy={refreshListPsy}
                        />}
                    />
                    <Route path={routes.wordle} exact element={
                        <RequireAuth>
                            <Wordl
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setSeverity={setSeverity}
                                setTransition={setTransition}
                                setOpenBackdrop={setOpenBackdrop}
                                zoom={zoom}
                                setZoom={setZoom}
                                findPosition={findPosition}
                                navigateRoute={navigateRoute}
                                phone={phone}
                                tablet={tablet}
                            />
                        </RequireAuth>}
                    />
                    <Route path={routes.type} exact element={
                        <RequireAuth>
                            <Theme
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setSeverity={setSeverity}
                                setTransition={setTransition}
                                setOpenBackdrop={setOpenBackdrop}
                                zoom={zoom}
                                setZoom={setZoom}
                                findPosition={findPosition}
                                identifier='ressource'
                                navigateRoute={navigateRoute}
                                setBreadcrumb={setBreadcrumb}
                                setTheme={setTheme}
                                handleFavoris={handleFavoris}
                                refreshFavoris={refreshFavoris}
                                phone={phone}
                                tablet={tablet}
                            />
                        </RequireAuth>}
                    />
                    <Route path={routes.theme} exact element={
                        <RequireAuth auth={'full'}>
                            <Type
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setSeverity={setSeverity}
                                setTransition={setTransition}
                                setOpenBackdrop={setOpenBackdrop}
                                zoom={zoom}
                                setZoom={setZoom}
                                findPosition={findPosition}
                                identifier='ressource'
                                navigateRoute={navigateRoute}
                                setBreadcrumb={setBreadcrumb}
                                setTheme={setTheme}
                                refreshFavoris={refreshFavoris}
                                phone={phone}
                                tablet={tablet}
                            />
                        </RequireAuth>}
                    />
                    {!refresh && <Route path={routes.ressource} exact element={
                        <RequireAuth auth={'full'}>
                            <Ressource
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setSeverity={setSeverity}
                                setTransition={setTransition}
                                setOpenBackdrop={setOpenBackdrop}
                                zoom={zoom}
                                setZoom={setZoom}
                                findPosition={findPosition}
                                identifier='ressource'
                                navigateRoute={navigateRoute}
                                setBreadcrumb={setBreadcrumb}
                                setTheme={setTheme}
                                setRefresh={setRefresh}
                                handleFavoris={handleFavoris}
                                refreshFavoris={refreshFavoris}
                                phone={phone}
                                tablet={tablet}
                            />
                        </RequireAuth>}
                    />}
                    <Route path={routes.chat} exact element={
                        <RequireAuth canGoBack={canGoBack}>
                            <Chat
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setSeverity={setSeverity}
                                setTransition={setTransition}
                                setOpenBackdrop={setOpenBackdrop}
                                zoom={zoom}
                                setZoom={setZoom}
                                findPosition={findPosition}
                                phone={phone}
                                navigateRoute={navigateRoute}
                                tablet={tablet}
                                setDisableMenu={setDisableMenu}
                                isChat={true}
                            />
                        </RequireAuth>}
                    />
                    <Route path={routes.visio} exact element={
                        <RequireAuth canGoBack={canGoBack}>
                            <Visio
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setSeverity={setSeverity}
                                setTransition={setTransition}
                                setOpenBackdrop={setOpenBackdrop}
                                zoom={zoom}
                                setZoom={setZoom}
                                findPosition={findPosition}
                                phone={phone}
                                navigateRoute={navigateRoute}
                                tablet={tablet}
                                setDisableMenu={setDisableMenu}
                                isVisio={true}
                            />
                        </RequireAuth>}
                    />
                    <Route path={"*"} element={<Error />} />
                </Routes>
            </div>
        </div>
    );
}