import * as React from 'react';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { findIcone, FormatUrl } from '../../helper/utils';
import { getFavoris } from '../../Security/favorisStorage';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';

export default function Search({ zoom, ressources, navigateRoute, handleDelete, search, handleFavoris, refreshFavoris }) {
    document.title = 'Espace Salarié - Ressources'

    return (
        <div style={{maxWidth: 1500, margin: 'auto', textAlign: 'center'}}>
            <Typography variant='h4' style={{color: '#75ADBE', marginBottom: 10}}>Recherche</Typography>
            <Typography variant='body1' sx={{mb: 3}}>Découvrez nos ressources liées à votre recherche.</Typography>
            <Chip label={search} color="secondary" onDelete={handleDelete} sx={{textTransform: 'capitalize', mb: 1}} />
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
                <Typography variant='body2'>{ressources.length || 0} résultat(s) trouvé(s).</Typography>
            </Zoom>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 20}}>
                {!!ressources.length && ressources.map((ressource) => {
                    return (
                        <div
                            key={ressource.id}
                            style={{
                                width: 300,
                                marginRight: 30,
                                cursor: 'pointer',
                                marginBottom: 20,
                            }}
                            className='ressourceCard'
                        >
                        <Box
                            variant='contained'
                            color='secondary'
                            sx={{
                                width: 300,
                                mr: 5,
                                height: 160,
                                backgroundColor: '#ACCDD8',
                                position: 'relative',
                            }}
                            onClick={() => navigateRoute(`/ressources/${ressource.theme}/${FormatUrl(ressource.format)}/${ressource.id}`)}
                        >
                             <div
                                style={{
                                    position: 'absolute',
                                    top: 30,
                                    left: 40,
                                    right: 20,
                                }}
                            >
                                <div style={{display: 'flex', flexFlow: 'column wrap', height: 40}}>
                                    <img src={findIcone(ressource.format)} alt={ressource.format} width={40} style={{zIndex: 9999}} />
                                    <Typography variant='body2' sx={{mt: 1.2, ml: 1, textAlign: 'right'}}>{ressource.duree_de_lecture} min</Typography>
                                </div>
                                <Typography color='primary' sx={{fontWeight: 'bold', mb: 3, mt: 2}}>{ressource.titre}</Typography>
                            </div>
                        </Box>
                        <Box
                            variant='contained'
                            color='secondary'
                            sx={{
                                width: 300,
                                mr: 5,
                                height: 140,
                                position: 'relative',
                            }}
                            onClick={() => navigateRoute(`/ressources/${ressource.theme}/${FormatUrl(ressource.format)}/${ressource.id}`)}
                        >
                             <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 20,
                                    right: 20,
                                }}
                            >
                                <Typography color='primary' sx={{fontWeight: 'bold', mb: 3, mt: 2}}>{ressource.description}</Typography>
                            </div>
                        </Box>
                        <Box
                            variant='contained'
                            color='secondary'
                            sx={{
                                width: 300,
                                ml: 1,
                                height: 'auto',
                                position: 'relative',
                            }}
                        >
                            <div style={{display: 'flex'}}>
                                {!refreshFavoris ? <IconButton onClick={() => handleFavoris(ressource)}>
                                    { getFavoris() && getFavoris().find((fav) => fav.id === ressource.id) ? <StarIcon color='secondary' /> : <StarOutlineIcon color='secondary' />}
                                </IconButton> : <IconButton>
                                    <StarIcon color='secondary' />
                                </IconButton>}
                                <Typography variant='body2' sx={{mt: 1.2, ml: 1}}>Noté comme favori</Typography>
                            </div>
                        </Box>
                    </div>)
                })}
            </div>
        </div>
    )
}