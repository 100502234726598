import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography
} from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InfoPopup({ open, handleClose }) {

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="info-popup-description"
        >
                <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                    Information sur le jeu
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="info-popup-description" component='div'>
                        <div className="letter" id={'correct'} style={{maxWidth: 45, margin: 'auto', marginTop: 20}}>
                            R
                        </div>
                        <Typography style={{marginTop: 5}} variant='body1'>Une lettre bleue est correct, bien placée.</Typography>
                        <div className="letter" id={'almost'} style={{maxWidth: 45, margin: 'auto', marginTop: 20}}>
                            S
                        </div>
                        <Typography style={{marginTop: 5}} variant='body1'>Une lettre rose est correct, mais mal placée.</Typography>
                        <div className="letter" id={'error'} style={{maxWidth: 45, margin: 'auto', marginTop: 20}}>
                            T
                        </div>
                        <Typography style={{marginTop: 5}} variant='body1'>Une lettre noire est incorrecte.</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
        </Dialog>
    );
}
