import * as React from 'react';
import {
    Button,
    Dialog,
    Grid,
    DialogContent,
    DialogContentText,
    Slide,
    useMediaQuery,
    Typography,
    DialogActions,
    Box,
    CircularProgress,
    IconButton,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import PsyCard from './ListPsysItem/PsyCard';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatIcon from '@mui/icons-material/Chat';
import VideocamIcon from '@mui/icons-material/Videocam';
import { toUTF8, TransitionLeft } from '../../helper/utils';
import { styled } from '@mui/material/styles';
import Planning from './PsyPopupItem/Planning';
import { apiFetch } from '../../Security/apiFetch';
import ConfirmPopup from '../../components/ConfirmPopup';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-elevation': {
        padding: 0,
        borderRadius: 18,
    },
    '& .MuiDialogContent-root': {
        padding: 0,
    }
}));

export default function PsyPopup({
    open,
    handleClose,
    specialities,
    handlePsyFavoris,
    refreshFavoris,
    setText,
    setSeverity,
    setTransition,
    setOpenSnackbar,
    isManagement,
    navigateRoute,
    setIsActive,
    isActive,
    phone,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [psy, setPsy] = React.useState(false)
    const [openConfirmPopup, setOpenConfirmPopup] = React.useState(false)

    React.useEffect(() => {
        (async () => {
            if (open) {
                const getPsy = await apiFetch(`/pros/${open.code_perso_pro}`)
                if (getPsy && getPsy.success) {
                    setPsy(getPsy.data)
                } else {
                    setText('Désolé, une erreur s\'est produite, veuillez réessayer !')
                    setSeverity('error')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                    handleClose()
                }
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleLaunchConsultation = () => {
        navigateRoute(`/${openConfirmPopup.type}/${openConfirmPopup.code}`)
        setOpenConfirmPopup(false)
    }
    return (
        <CustomDialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            maxWidth={'xl'}
            fullWidth={true}
            onClose={handleClose}
            aria-describedby="psy-popup-description"
        >
            <DialogContent>
                <DialogContentText id="psy-popup-description" component='div'>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <PsyCard
                                data={open}
                                specialities={specialities}
                                elevation={false}
                                handlePsyFavoris={handlePsyFavoris}
                                refreshFavoris={refreshFavoris}
                                navigateRoute={navigateRoute}
                                isVisioTab={isActive[2]}
                                psy={psy}
                            />
                            <Box
                                sx={{
                                    mt: 2,
                                    textAlign: 'center',
                                }}
                            >
                                <Typography variant='h6' sx={{fontWeight: 'bold', mb: 2}}>MES DISPONIBILITÉS</Typography>
                                <Button
                                    variant={isActive[0] ? 'contained' : 'outlined'}
                                    sx={{color: '#75ADBE', mr: 3}}
                                    startIcon={<PhoneIcon />}
                                    onClick={() => setIsActive([true, false, false])}
                                >
                                    {!phone && 'PAR TÉLÉPHONE'}
                                </Button>
                                {open.tchat_actif && <Button
                                    variant={isActive[1] ? 'contained' : 'outlined'}
                                    sx={{color: '#75ADBE', mr: 3}}
                                    startIcon={<ChatIcon />}
                                    onClick={() => setIsActive([false, true, false])}
                                >
                                    {!phone && 'PAR TCHAT'}
                                </Button>}
                                {open.visio_active && <Button
                                    variant={isActive[2] ? 'contained' : 'outlined'}
                                    sx={{color: '#75ADBE'}}
                                    startIcon={<VideocamIcon />}
                                    onClick={() => setIsActive([false, false, true])}
                                >
                                    {!phone && 'PAR VISIOCONFÉRENCE'}
                                </Button>}
                            </Box>
                            <Box
                                sx={{
                                    ml: 2,
                                    mb: 2,
                                    mt: 2,
                                }}
                            >
                                {psy && !phone ?
                                    <Planning
                                        availability={
                                            isActive[2] ? psy.planning_hebdomadaire.visio :
                                            isActive[1] ? psy.planning_hebdomadaire.tchat :
                                            psy.planning_hebdomadaire.telephone
                                        }
                                        isManagement={isManagement}
                                    /> :
                                    !phone && <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                            marginTop: 20
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>}
                            </Box>
                            <Box
                                sx={{
                                    ml: 2,
                                    mb: 2,
                                    mt: 2,
                                    textAlign: 'center',
                                }}
                            >
                                {(isActive[1] || isActive[2]) &&
                                <Button
                                    variant={'outlined'}
                                    onClick={() => setOpenConfirmPopup({type: isActive[1] ? 'chat' : 'visio',code: open.code_perso_pro})}
                                    disabled={
                                        (isActive[1] &&
                                        psy.possibilites_de_consultation_actuelles &&
                                        !psy.possibilites_de_consultation_actuelles.find(i => i === 'tchat')) ||
                                        (isActive[2] &&
                                        moment().format('HH:mm') <= '07:00' && moment().format('HH:mm') >= '21:00') ||
                                        (isActive[2] &&
                                        psy.possibilites_de_consultation_actuelles &&
                                        !psy.possibilites_de_consultation_actuelles.find(i => i === 'visio'))
                                    }
                                >
                                    Démarrer une consultation
                                </Button>}
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12} >
                            <IconButton style={{float: 'right'}} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                            <Box
                                sx={{
                                    padding: phone ? 
                                        "10px 20px" :
                                        '20px 40px',
                                    mt: {xl: 8.75, xs: 2},
                                    mr: {xl: 5, xs: 1},
                                    ml: {xs: 1},
                                    textAlign: 'justify',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '8',
                                    lineClamp: '8' ,
                                    WebkitBoxOrient: 'vertical',
                                }}
                            >
                                <Typography variant='h6' sx={{fontWeight: 'bold', mb: 2, color: '#75ADBE'}}>MA FORMATION</Typography>
                                <Box style={{columns: 2, columnGap: '3em', whiteSpace: 'pre-line'}}>
                                    {psy && psy.presentation_formation && <Typography variant='body1'>{toUTF8(psy.presentation_formation.replace(/<[^>]+>/g, ''))}</Typography>}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    backgroundColor: isManagement ? '#01304A' : '#75ADBE',
                                    padding: phone ? '10px 20px' : '20px 40px',
                                    mr: {xl: 5, xs: 1},
                                    mb: {xl: 2, xs: 1},
                                    borderRadius: 8,
                                    mt: {xl: 3.75, xs: 1},
                                    ml: {xs: 1},
                                    color: isManagement ? 'white' : 'black',
                                }}
                            >
                                <Typography variant='h6' sx={{fontWeight: 'bold', mb: 2}}>MA PRATIQUE</Typography>
                                <Box style={{columns: 2, columnGap: '3em', maxHeight: 250, overflowX: 'scroll', whiteSpace: 'pre-line'}}>
                                    {psy && psy.presentation_pratique && <Typography variant='body1' sx={{whiteSpace: 'pre-line'}}>
                                        {toUTF8(psy.presentation_pratique.replace(/<[^>]+>/g, ''))}
                                    </Typography>}
                                </Box>
                            </Box>
                            {psy && <Typography
                                variant='body1'
                                color='primary'
                                sx={{mb: 2, textAlign: 'right', mr: {xl: 5, xs: 1}, fontWeight: 'bold'}}
                            >
                                CODE ADELI : {psy.numero_adeli}
                            </Typography>}
                        </Grid>
                    </Grid>
                    <ConfirmPopup
                        open={openConfirmPopup}
                        handleClose={() => setOpenConfirmPopup(false)}
                        handleAccept={handleLaunchConsultation}
                        title={'Vous vous apprêtez à lancer une consultation par Tchat'}
                    />
                </DialogContentText>
            </DialogContent>
            {phone && <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>Fermer</Button>
            </DialogActions>}
        </CustomDialog>
    );
}
