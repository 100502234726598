export const routes = {
    login: '/login',
    home: '/',
    ressources: '/ressources',
    theme: '/ressources/:id',
    type: '/ressources/:id/:type',
    ressource: '/ressources/:id/:type/:uuid',
    psy: '/psy',
    psyId: '/psy/:id',
    chat: '/chat/:id',
    visio: '/visio/:id',
    management: '/management',
    error: '/error',
    survey: '/survey',
    wordle: '/ressources/:id/wordle'
}