import React, { useRef } from "react";
import "../index.css";
import useVideoPlayer from '../hooks/useVideoPlayer'

const VideoPlayer = ({ videoUrl, autoPlay, externRef }) => {
    const videoElement = useRef(null);
    const {
        handleOnTimeUpdate,
    } = useVideoPlayer(externRef || videoElement);

    return (
        <div className="container">
            <div className="video-wrapper">
                <video
                    src={videoUrl}
                    ref={videoElement}
                    onTimeUpdate={handleOnTimeUpdate}
                    autoPlay={autoPlay}
                    id='ressourceVideo'
                    controls
                    controlsList="nodownload noplaybackrate"
                />
            </div>
        </div>
    );
};

export default VideoPlayer;