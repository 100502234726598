import React, {useState} from 'react';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PsyCard from './ListPsysItem/PsyCard';
import PhonePopup from './ListPsysItem/PhonePopup';

export default function ListPsys({ zoom, setZoom, psys, specialities, setOpenPopup, handlePsyFavoris, refreshFavoris, navigateRoute, phone }) {
    const [openPhonePopup, setOpenPhonePopup] = useState(false)

    React.useEffect(() => {
        setZoom(true)
        return () => setZoom(false)
    }, [setZoom])

    return (
        <div style={{marginTop: 400}}>
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
                <Grid container spacing={phone ? 0 : 3} sx={{maxWidth: 1600, margin: 'auto', mt: -50}}>
                    {psys.length ? psys.map((psy) => {
                        return (
                            <React.Fragment key={psy.code_perso_pro}>
                                <Grid item xs={12} sm={6}>
                                    <PsyCard
                                        handlePsyFavoris={handlePsyFavoris}
                                        setOpenPopup={setOpenPopup}
                                        data={psy}
                                        specialities={specialities}
                                        elevation={true}
                                        refreshFavoris={refreshFavoris}
                                        setOpenPhonePopup={setOpenPhonePopup}
                                        navigateRoute={navigateRoute}
                                        phone={phone}
                                    />
                                </Grid>
                            </React.Fragment>
                        )
                    }) : 
                    <Typography sx={{margin: 'auto', mt: 3}}>Aucun psychologue ne correspond à ces critères</Typography>}
                    <PhonePopup open={openPhonePopup} handleClose={() => setOpenPhonePopup(false)} />
                </Grid>
            </Zoom>
        </div>
    )
}