import React, { useState, useRef } from 'react';
import { getPsyFavoris } from '../../Security/favorisStorage';
import PhonePopup from './ListPsysItem/PhonePopup';
import PsyCard from './ListPsysItem/PsyCard';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import Carousel from 'react-elastic-carousel'
import "react-multi-carousel/lib/styles.css";
import { apiFetch } from '../../Security/apiFetch';

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 3 },
];

export default function Favoris({
    handlePsyFavoris,
    refreshFavoris,
    navigateRoute,
    specialities,
    zoom,
    setZoom,
    setOpenPopup,
    setOpenBackdrop,
    phone,
}) {
    const [openPhonePopup, setOpenPhonePopup] = useState(false)
    const [psys, setPsys] = useState([])
    const carouselRef = useRef(null);

    React.useEffect(() => {
        (async () => {
            let preventDuplicate = []
            if (getPsyFavoris() && !!getPsyFavoris().length) {
                const requests = getPsyFavoris().map(async (code_perso_pro) => {
                    const getFav = await apiFetch(`/pros/${code_perso_pro}`)
                    if (getFav.success) {
                        preventDuplicate.push(getFav.data)
                    }
                })
                Promise.all(requests).then(async () => {
                    setPsys(preventDuplicate)
                    setOpenBackdrop(false)
                })
            }
            
            setZoom(true)
        })()
        return () => {
            setZoom(false)
            setOpenBackdrop(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setZoom, refreshFavoris])

    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };

    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(9);
        }
    };

    return (
        <>
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '600ms' }}>
                {(getPsyFavoris() && !!getPsyFavoris().length) ? <div
                    style={{
                        maxWidth: 1600,
                        margin: 'auto',
                        border: '5px solid #01304A',
                        borderRadius: 15,
                        padding: '10px 30px 30px 30px',
                        marginTop: 35,
                    }}
                >
                    <Typography 
                        variant='h5'
                        sx={{
                            textAlign: 'center',
                            mb: 2
                        }}
                    >
                        Mes psychologues favoris <StarIcon color="secondary" fontSize="large" />
                    </Typography>
                    <Carousel
                        ref={carouselRef}
                        breakPoints={breakPoints}
                        onPrevStart={onPrevStart}
                        onNextStart={onNextStart}
                        disableArrowsOnEnd={false}
                    >
                        {psys && psys.map((psy, i) => (
                                <div key={i} style={{padding: 14}}>
                                    <PsyCard
                                        data={psy}
                                        specialities={specialities}
                                        handlePsyFavoris={handlePsyFavoris}
                                        refreshFavoris={refreshFavoris}
                                        setOpenPhonePopup={setOpenPhonePopup}
                                        navigateRoute={navigateRoute}
                                        phone={phone}
                                        elevation={true}
                                        isFavoris={true}
                                        setOpenPopup={setOpenPopup}
                                    />
                                </div>
                        ))}
                    </Carousel>
                    <PhonePopup open={openPhonePopup} handleClose={() => setOpenPhonePopup(false)} />
                </div> : <div></div>}
            </Zoom>
        </>
        
    )
}