import React from 'react'
import {
    Typography,
    Avatar,
} from '@mui/material'
import avatar from '../../assets/No-avatar.png'
import moment from 'moment'

export default function Messages({ message, psyData, user }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: "column",
                alignItems: message.author.startsWith('anonyme') ? "flex-end" : "flex-start",
            }}
        >
            <div style={{display: 'flex', maxWidth: '75%'}}>
                <div>
                    <Avatar
                        src={
                            message.author.startsWith('anonyme') ? 
                                avatar : 
                                psyData.photo_url
                        } 
                        alt={
                            message.author.startsWith('anonyme') ? 
                                user.identity : 
                                psyData.pseudo
                        } 
                        sx={{
                            width: 50,
                            height: 50,
                            mr: 2,
                            mt: 1,
                        }}
                    />
                    {!message.author.startsWith('anonyme') && <><Typography variant='caption' sx={{ml: 0.5}}>{psyData.pseudo}</Typography><br /></>}
                    {!message.author.startsWith('anonyme') && <Typography variant='caption' sx={{ml: 0.5}}>{psyData.code_perso_pro}</Typography>}
                </div>
                <div style={{display: 'block'}}>
                <Typography
                    variant='body1'
                    sx={{
                        textAlign: 'left',
                        backgroundColor: message.author.startsWith('anonyme') ? '#ACCDD8' : '#F2F2F2',
                        mt: 1,
                        p: 2,
                        borderRadius: 2,
                    }}
                >
                    {message.body}
                </Typography>
                <Typography variant='caption' style={{fontSize: 10, textAlign: "right", paddingTop: 4}}>
                    {moment(message.dateCreated).format('DD MMMM YYYY à HH:mm')}
                </Typography>
                </div>
            </div>
        </div>
    )
}