import React, { useContext } from "react";
import { AppContext } from "../wordl";
import { Typography, Button } from "@mui/material";

function GameOver({ reload }) {
    const {
        currAttempt,
        gameOver,
        correctWord,
    } = useContext(AppContext);

    const handleReplay = () => {
        reload()
    }

    return (
        <div className="gameOver">
            <Typography variant='h5' style={{marginTop: 20}}>
                {gameOver.guessedWord
                    ? "Vous avez gagné"
                    : `Dommage ! La bonne réponse était ${correctWord}`}
            </Typography>
            {gameOver.guessedWord && (
                <Typography variant='h5' style={{marginTop: 20}}>Vous avez deviné en {currAttempt.attempt} tentatives</Typography>
            )}
            {gameOver && <Button variant='contained' color='primary' style={{marginTop: 20}} onClick={handleReplay}>Rejouer</Button>}
        </div>
    );
}

export default GameOver;