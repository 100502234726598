import React from 'react'
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AutoComplete from '../../components/Autocomplete';

export default function Search({
    zoom,
    setZoom,
    specialities,
    languages,
    name,
    setName,
    code,
    setCode,
    handleSearch,
    setSpeciality,
    speciality,
    setLanguage,
    language,
    consultation,
    setConsultation,
    isManagement,
    phone,
}) {

    React.useEffect(() => {
        setZoom(true)
        return () => setZoom(false)
    }, [setZoom])

    const handleForceSearch = (e) => {
        if (e.keyCode === 13) {
            handleSearch()
        }
    }

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
            <Box
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    mt: 6
                }}
                onKeyDown={handleForceSearch}
            >
                <Typography variant='h5' color='primary' sx={{mb: 5}}>Je cherche un {isManagement ? 'consultant' : 'psychologue'}</Typography>
                <Box
                    sx={{
                        display: phone ? 'block' : 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <AutoComplete
                        options={specialities}
                        label={'Spécialités'}
                        placeholder={''}
                        setValue={setSpeciality}
                        value={speciality}
                        sx={{mt: {xl: 0, xs: 2}}}
                        phone={phone}
                    />
                    <AutoComplete
                        options={languages}
                        label={'Langues parlées'}
                        placeholder={''}
                        setValue={setLanguage}
                        value={language}
                        sx={{mt: {xl: 0, xs: 2}}}
                        phone={phone}
                    />
                    <TextField
                        id="name"
                        name='name'
                        label="Prénom"
                        variant="outlined"
                        value={name}
                        onChange={
                            (e) => setName(e.target.value)
                        }
                        sx={{mr: {xl: 2.2, xs: 0}, width: 280, mt: {xl: 0, xs: 2}}}
                    />
                    <TextField
                        id="code"
                        name="code"
                        label="Code"
                        variant="outlined"
                        value={code}
                        onChange={
                            (e) => setCode(e.target.value)
                        }
                        sx={{mr: {xl: 2.2, xs: 0}, width: 280, mt: {xl: 0, xs: 2}}}
                    />
                    <AutoComplete
                        options={[
                            {id: 1, nom: 'Téléphone'},
                            {id: 2, nom: 'Tchat'},
                            {id: 3, nom: 'Visio'}
                        ]}
                        label={'Consultation'}
                        placeholder={''}
                        setValue={setConsultation}
                        value={consultation}
                        sx={{mt: {xl: 0, xs: 2}}}
                        phone={phone}
                    />
                </Box>
                <Button variant='contained' color='primary' sx={{mt: 5}} onClick={handleSearch}>C'est parti !</Button>
            </Box>
        </Zoom>
    )
}