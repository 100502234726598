import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Slide,
    useMediaQuery,
    Typography
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { getNum } from '../../../Security/Authentifier';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PhonePopup({ open, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-describedby="info-popup-description"
        >
                <DialogContent>
                    <DialogContentText id="info-popup-description" component='div'>
                        <Typography
                            style={{marginTop: 5}} 
                            color='primary' 
                            variant='body1'
                        >
                            {getNum() ? `Veuillez composer le numéro ${formatNum(getNum())} sur votre téléphone` : 
                            'Pour contacter un psychologue, veuillez utiliser le numéro vert mis à votre disposition'}
                        </Typography>
                        <Typography
                            style={{marginTop: 5}}
                            color='primary'
                            variant='body1'
                        >
                            Lors de votre appel, vous devrez communiquer le code professionnel de {open.pseudo}, qui est le <b>{open.code_perso_pro}</b>.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
        </Dialog>
    );
}

const formatNum = (num) => {
    return `${num[0]} ${num[1]}${num[2]}${num[3]} ${num[4]}${num[5]}${num[6]} ${num[7]}${num[8]}${num[9]}`
}
