import { Slide } from "@mui/material";
import { routes } from "../Security/Routes";
import articleIcone from '../assets/ressource/icone/icone_article.png'
import iconeJeux from '../assets/ressource/icone/icone_jeux.png'
import iconeQuiz from '../assets/ressource/icone/icone_quizz.png'
import iconePodcast from '../assets/ressource/icone/icone_podcast.png'
import iconeVideo from '../assets/ressource/icone/icone_video.png'
import nostress from '../assets/ressource/theme_nostress.png'
import addictions from '../assets/ressource/theme_addictions.png'
import communication from '../assets/ressource/theme_communication.png'
import emotions from '../assets/ressource/theme_emotions.png'
import epreuves from '../assets/ressource/theme_epreuves.png'
import equilibre from '../assets/ressource/theme_equilibre.png'
import organisation from '../assets/ressource/theme_organisation.png'
import psychologie from '../assets/ressource/theme_psychologie.png'
import confiance from '../assets/ressource/theme_confiance.png'
import amour from '../assets/ressource/theme_amour.png'
import family from '../assets/ressource/theme_family.png'
import travail from '../assets/ressource/theme_travail.png'
import management from '../assets/ressource/theme_management.png'
export function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

export function isNotConnectedRoutes(route) {
    return route === '/login'
}

export function isKnownRoutes(route, location) {
    const r = route.lastIndexOf('/')
    let formattedRoute = route
    if (r > 0) {
        formattedRoute = route.substring(0,r) + '/:id'
    }
    return Object.keys(routes).find((key) => !!(routes[key] === location.state || formattedRoute)); 
}

export function FormatUrl(string) {
    let str = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    str.replace(/\s/g, '')
    return str.toLowerCase()
}

export function translateFormat(string) {
    switch (string) {
        case 'pdf':
            return 'NOS ARTICLES'
        case 'podcast':
            return 'NOS PODCASTS'
        case 'video':
            return 'NOS VIDÉOS'
        case 'quiz':
            return 'NOS QUIZ'
        default:
            return null
    }
}

export function translateTitle(string) {
    switch (string) {
        case 'Psycho':
            return 'Psychologie'
        case 'pdf':
            return 'articles'
        case 'podcast':
            return 'podcasts'
        case 'video':
            return 'vidéos'
        default:
            return string
    }
}

export function toUTF8(text) {
    let res = text.replace(/&nbsp;/g, ' ');
    res = res.replace(/&iexcl;/g, '¡');
    res = res.replace(/&cent;/g, '¢');
    res = res.replace(/&pound;/g, '£');
    res = res.replace(/&curren;/g, '¤');
    res = res.replace(/&yen;/g, '¥');
    res = res.replace(/&brvbar;/g, '¦');
    res = res.replace(/&sect;/g, '§');
    res = res.replace(/&uml;/g, '¨');
    res = res.replace(/&copy;/g, '©');
    res = res.replace(/&ordf;/g, 'ª');
    res = res.replace(/&laquo;/g, '«');
    res = res.replace(/&not;/g, '¬');
    res = res.replace(/&shy;/g, '­');
    res = res.replace(/&reg;/g, '®');
    res = res.replace(/&macr;/g, '¯');
    res = res.replace(/&deg;/g, '°');
    res = res.replace(/&plusmn;/g, '±');
    res = res.replace(/&sup2;/g, '²');
    res = res.replace(/&sup3;/g, '³');
    res = res.replace(/&acute;/g, '´');
    res = res.replace(/&micro;/g, 'µ');
    res = res.replace(/&para;/g, '¶');
    res = res.replace(/&middot;/g, '·');
    res = res.replace(/&cedil;/g, '¸');
    res = res.replace(/&sup1;/g, '¹');
    res = res.replace(/&ordm;/g, 'º');
    res = res.replace(/&raquo;/g, '»');
    res = res.replace(/&frac14;/g, '¼');
    res = res.replace(/&frac12;/g, '½');
    res = res.replace(/&frac34;/g, '¾');
    res = res.replace(/&iquest;/g, '¿');
    res = res.replace(/&Agrave;/g, 'À');
    res = res.replace(/&Aacute;/g, 'Á');
    res = res.replace(/&Acirc;/g, 'Â');
    res = res.replace(/&Atilde;/g, 'Ã');
    res = res.replace(/&Auml;/g, 'Ä');
    res = res.replace(/&Aring;/g, 'Å');
    res = res.replace(/&AElig;/g, 'Æ');
    res = res.replace(/&Ccedil;/g, 'Ç');
    res = res.replace(/&Egrave;/g, 'È');
    res = res.replace(/&Eacute;/g, 'É');
    res = res.replace(/&Ecirc;/g, 'Ê');
    res = res.replace(/&Euml;/g, 'Ë');
    res = res.replace(/&Igrave;/g, 'Ì');
    res = res.replace(/&Iacute;/g, 'Í');
    res = res.replace(/&Icirc;/g, 'Î');
    res = res.replace(/&Iuml;/g, 'Ï');
    res = res.replace(/&ETH;/g, 'Ð');
    res = res.replace(/&Ntilde;/g, 'Ñ');
    res = res.replace(/&Ograve;/g, 'Ò');
    res = res.replace(/&Oacute;/g, 'Ó');
    res = res.replace(/&Ocirc;/g, 'Ô');
    res = res.replace(/&Otilde;/g, 'Õ');
    res = res.replace(/&Ouml;/g, 'Ö');
    res = res.replace(/&times;/g, '×');
    res = res.replace(/&Oslash;/g, 'Ø');
    res = res.replace(/&Ugrave;/g, 'Ù');
    res = res.replace(/&Uacute;/g, 'Ú');
    res = res.replace(/&Ucirc;/g, 'Û');
    res = res.replace(/&Uuml;/g, 'Ü');
    res = res.replace(/&Yacute;/g, 'Ý');
    res = res.replace(/&THORN;/g, 'Þ');
    res = res.replace(/&szlig;/g, 'ß');
    res = res.replace(/&agrave;/g, 'à');
    res = res.replace(/&aacute;/g, 'á');
    res = res.replace(/&acirc;/g, 'â');
    res = res.replace(/&atilde;/g, 'ã');
    res = res.replace(/&auml;/g, 'ä');
    res = res.replace(/&aring;/g, 'å');
    res = res.replace(/&aelig;/g, 'æ');
    res = res.replace(/&ccedil;/g, 'ç');
    res = res.replace(/&egrave;/g, 'è');
    res = res.replace(/&eacute;/g, 'é');
    res = res.replace(/&ecirc;/g, 'ê');
    res = res.replace(/&euml;/g, 'ë');
    res = res.replace(/&igrave;/g, 'ì');
    res = res.replace(/&iacute;/g, 'í');
    res = res.replace(/&icirc;/g, 'î');
    res = res.replace(/&iuml;/g, 'ï');
    res = res.replace(/&eth;/g, 'ð');
    res = res.replace(/&ntilde;/g, 'ñ');
    res = res.replace(/&ograve;/g, 'ò');
    res = res.replace(/&oacute;/g, 'ó');
    res = res.replace(/&ocirc;/g, 'ô');
    res = res.replace(/&otilde;/g, 'õ');
    res = res.replace(/&ouml;/g, 'ö');
    res = res.replace(/&divide;/g, '÷');
    res = res.replace(/&oslash;/g, 'ø');
    res = res.replace(/&oelig;/g, 'œ');
    res = res.replace(/&ugrave;/g, 'ù');
	res = res.replace(/&uacute;/g, 'ú');
	res = res.replace(/&ucirc;/g, 'û');
	res = res.replace(/&uuml;/g, 'ü');
	res = res.replace(/&yacute;/g, 'ý');
	res = res.replace(/&thorn;/g, 'þ');
	res = res.replace(/&yuml;/g, 'ÿ');
    res = res.replace(/&rsquo;/g, "'")
    res = res.replace(/&hellip;/g, "...")
	return res;
}

export const findIcone = (item) => {
    switch(item) {
        case 'pdf':
            return articleIcone
        case 'podcast':
            return iconePodcast
        case 'video':
            return iconeVideo
        case 'quiz':
            return iconeQuiz
        default:
            return iconeJeux
    }
}

export const findImg = (id) => {
    switch(id) {
        case 'Travail':
            return travail
        case 'Émotions':
            return emotions
        case 'Communication':
            return communication
        case 'Addiction':
            return addictions
        case 'Équilibre':
            return equilibre
        case 'Épreuve de vie':
            return epreuves
        case 'Organisation':
            return organisation
        case 'Psycho':
            return psychologie
        case 'Famille':
            return family
        case 'Confiance en soi':
            return confiance
        case 'Vie amoureuse':
            return amour
        case 'Management':
            return management
        default:
            return nostress
    }
}