import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutoComplete({ options, label, placeholder, setValue, value, sx, phone }) {
    return (
        <Autocomplete
            multiple
            id="checkboxes-select"
            options={options}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option) => option.nom || option.langue}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.nom || option.langue}
                </li>
            )}
            isOptionEqualToValue={(e, r) => {
                if (e.langue) return e.langue === r.langue
                return e.id === r.id
            }}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            value={value}
            style={{ width: 280, margin: phone ? 'auto' : '0px 20px 0px 0px' }}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder} sx={sx} />
            )}
        />
    );
}