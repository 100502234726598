import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatIcon from '@mui/icons-material/Chat';
import VideocamIcon from '@mui/icons-material/Videocam';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import moment from 'moment'
import { toUTF8 } from '../../../helper/utils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import DescriptionPopup from './PsyCardItem/DescriptionPopup';
import { getPsyFavoris } from '../../../Security/favorisStorage';
import ConfirmPopup from '../../../components/ConfirmPopup';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 600,
        padding: '20px 40px',
        boxShadow: '-10px 12px 28px #989898',
        borderRadius: 5,
    },
}));

export default function PsyCard({
    data,
    specialities,
    setOpenPopup,
    elevation,
    handlePsyFavoris,
    refreshFavoris,
    setOpenPhonePopup,
    navigateRoute,
    phone,
    isFavoris,
    isVisioTab,
    psy,
}) {
    const [isShowMore, setIsShowMore] = useState(false)
    const [openDescriptionPopup, setOpenDescriptionPopup] = useState(false)
    const [openConfirmPopup, setOpenConfirmPopup] = useState(false)

    const findBgColor = (green, orange, grey) => {
        if (!elevation) {
            if (data.disponibilite === 1) {
                if (isVisioTab) {
                    return psy && psy.possibilites_de_consultation_actuelles.find(i => i === 'visio') ?
                        green : grey
                }
                return psy && (psy.possibilites_de_consultation_actuelles.find(i => i === 'téléphone') || 
                psy.possibilites_de_consultation_actuelles.find(i => i === 'tchat')) ?
                    green : grey
            } else if (data.disponibilite === 2) {
                return orange
            }
            return grey
        }
        return data.disponibilite === 1 ? green : data.disponibilite === 2 ? orange: grey
    }
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: findBgColor('#44b700', '#EF7815', '#B5B5B5'),
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            width: 25,
            height: 25,
            left: phone ? '80%' : isFavoris ? 205 : 230,
            borderRadius: '50%',
            '&::after': {
                position: 'absolute',
                top: 4,
                left: 4,
                width: '60%',
                height: '60%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: findBgColor('1px solid #44b700','1px solid #EF7815','1px solid #B5B5B5'),
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    const handleShowMore = () => {
        setIsShowMore(!isShowMore)
    }

    const handleShowDescription = async (item) => {
        setOpenDescriptionPopup(specialities.find(speciality => speciality.nom === item.nom))
    }

    const handleLaunchConsultation = () => {
        navigateRoute(`/${openConfirmPopup.type}/${openConfirmPopup.code}`)
        setOpenConfirmPopup(false)
    }

    return (
        <Paper
            sx={{
                mt: elevation ? 3 : 0,
                boxShadow: elevation ? '-5px 1px 12px #B5B5B5' : '',
                borderRadius: 4,
                maxWidth: isFavoris ? 470 : '100%',
                ml: {xs: 2.5, sm: 0},
                mr: {xs: 2.5, sm: 0},
            }}
            elevation={!elevation ? 0 : 1}
            className={elevation ? 'ressourceCard' : 'ressourceCardWithoutElevation'}
        >
            <Grid container spacing={0}>
                <Grid item xs={12} sm={isFavoris ? 6 : 4}>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        variant="dot"
                        style={{width: '100%', height: '100%'}}
                    >
                        <img
                            src={data.url_de_la_photo}
                            alt={data.pseudo}
                            style={{
                                borderRadius: 16,
                                width: phone ? '300px' : '100%',
                                height: !isFavoris ? '350px' : '100%',
                                margin: phone ? 'auto' : 'none'
                            }}
                        />
                    </StyledBadge>
                </Grid>
                <Grid item xs={12} sm={isFavoris ? 6 : 8} sx={{p: 2, pl: elevation ? 2 : 3}}>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Typography
                            sx={{fontWeight: 'bold', mt: 0.4, mr: 1, width: '100%'}}
                            color='primary'
                            variant='h6'
                            translate="no"
                        >
                            {data.pseudo}
                        </Typography>
                        {!refreshFavoris ? <IconButton onClick={() => handlePsyFavoris(data)}>
                            { getPsyFavoris() && getPsyFavoris().find((fav) => fav === data.code_perso_pro) ? <StarIcon color='secondary' /> : <StarOutlineIcon color='secondary' />}
                        </IconButton> : <IconButton>
                            <StarIcon color='secondary' />
                        </IconButton>}
                        {window.location.pathname.startsWith('/management') && !isFavoris && <div style={{width: '100%'}}>
                            <div
                                style={{
                                    backgroundColor: '#01304A',
                                    color: 'white',
                                    width: '170px',
                                    marginTop: 5,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '30px',
                                    borderRadius: 5,
                                    float: 'right',
                                }}
                            >
                                <Typography 
                                    variant='caption'
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                >
                                    Conseil en management
                                </Typography>
                            </div>
                        </div>}
                    </Box>
                    <Typography sx={{fontWeight: 'bold'}} color='primary' variant='body1'>{data.code_perso_pro}</Typography>
                    {data.langues ? data.langues.split(',').map((langue, i) => (
                        <img
                            src={"https://www.pros-consulte.com/wp-content/themes/divi-pros-consulte/img/flags/4x3/" + langue + ".svg"}
                            alt={langue}
                            width={20}
                            style={{marginRight: 10}}
                            key={i}
                        />
                    )) : 
                    <div style={{height: 15}}></div>}
                    {data.prochaine_dispo ?
                    <Typography variant='overline' style={{display: 'block'}}>
                        {`Disponible le ${moment(data.prochaine_dispo).format('DD/MM/YYYY à HH:mm')}`}
                    </Typography> : 
                    <div style={{height: 31}}></div>}
                    {!isFavoris && <Box
                        sx={{
                            mt: 1,
                            mb: 1,
                        }}
                    >
                        {data.specialites && data.specialites.map((speciality, i) => {
                            return (
                                i < 3 && <Button 
                                    variant="outlined"
                                    key={i}
                                    sx={{
                                        textTransform: 'capitalize',
                                        mr: 2,
                                        mt: 1,
                                    }}
                                    onClick={() => handleShowDescription(speciality)}
                                >
                                    {speciality.nom}
                                </Button>
                            )
                        })}
                        {data.specialites && data.specialites.length > 3 && <IconButton onClick={handleShowMore}>
                            {isShowMore ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                        </IconButton>}
                        {isShowMore && data.specialites.map((speciality, i) => {
                            return (
                                i >= 3 && <Button 
                                    variant="outlined"
                                    key={i}
                                    sx={{
                                        textTransform: 'capitalize',
                                        mr: 2,
                                        mt: 1,
                                    }}
                                    onClick={() => handleShowDescription(speciality)}
                                >
                                    {speciality.nom}
                                </Button>
                            )
                        })}
                    </Box>}
                    {!isFavoris && <Typography
                        variant='body2'
                        sx={{
                            maxWidth: '100%',
                            textAlign: 'justify',
                            overflow: elevation && 'hidden',
                            textOverflow: elevation && 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: elevation && isFavoris ? '3' : '4',
                            lineClamp: elevation && isFavoris ? '3' :  '4' ,
                            WebkitBoxOrient: elevation && 'vertical',
                        }}
                        color='primary'
                    >
                            {data.presentation_courte && toUTF8(data.presentation_courte.replace(/<[^>]+>/g, ''))}
                    </Typography>}
                    {setOpenPopup && <Grid container spacing={0} sx={{mt: 2, mb: 1, width: '100%'}}>
                        <Grid item sm={isFavoris ? 12 : 9} xs={isFavoris ? 12 : 6}>
                            <Button variant='outlined' sx={{mr: isFavoris ? 0 : 1}} onClick={() => setOpenPopup(data)}>
                                {'Voir le profil complet'}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            sm={isFavoris ? 12 : 3}
                            xs={isFavoris ? 12 : 6} 
                            sx={{
                                textAlign: isFavoris ? 'left' : 'right',
                                marginTop: isFavoris ? 5 : 0
                            }}
                        >
                            <StyledTooltip
                                title={
                                    data.disponibilite !== 1 ? 'Ce psychologue n\'est pas disponible pour le moment' :
                                    data.possibilites_de_consultation_actuelles && 
                                    !data.possibilites_de_consultation_actuelles.find(i => i === 'téléphone') ? 'Ce psychologue n\'est pas disponible en ce moment pour ce type de consultation' :
                                    ''
                                }
                                arrow
                            >
                                <span>
                                    <IconButton
                                        disabled={data.possibilites_de_consultation_actuelles && 
                                            !data.possibilites_de_consultation_actuelles.find(i => i === 'téléphone')}
                                        sx={{
                                            color: '#75ADBE'
                                        }}
                                        onClick={() => setOpenPhonePopup(data)}
                                    >
                                        <PhoneIcon />
                                    </IconButton>
                                </span>
                            </StyledTooltip>
                            {data.tchat_actif && 
                            <StyledTooltip
                                title={
                                    data.disponibilite !== 1 ? 'Ce psychologue n\'est pas disponible pour le moment' :
                                    data.possibilites_de_consultation_actuelles && 
                                    !data.possibilites_de_consultation_actuelles.find(i => i === 'tchat') ? 'Ce psychologue n\'est pas disponible en ce moment pour ce type de consultation' :
                                    ''
                                }
                                arrow
                            >
                                <span>
                                    <IconButton
                                        disabled={data.possibilites_de_consultation_actuelles && 
                                            !data.possibilites_de_consultation_actuelles.find(i => i === 'tchat')}
                                        sx={{
                                            color: '#75ADBE',
                                        }}
                                        onClick={() => setOpenConfirmPopup({type: 'chat', code: data.code_perso_pro})}
                                   >
                                        <ChatIcon />
                                    </IconButton>
                                </span>
                            </StyledTooltip>}
                            {data.visio_active && 
                            <StyledTooltip
                                title={
                                    moment().format('HH:mm') <= '07:00' && moment().format('HH:mm') >= '21:00' ? 'La visio est disponible de 07h00 à 21h00' : 
                                    data.disponibilite !== 1 ? 'Ce psychologue n\'est pas disponible pour le moment' : 
                                    data.possibilites_de_consultation_actuelles && 
                                    !data.possibilites_de_consultation_actuelles.find(i => i === 'visio') ? 'Ce psychologue n\'est pas disponible en ce moment pour ce type de consultation' : 
                                    ''
                                }
                                arrow
                            >
                                <span>
                                    <IconButton
                                        disabled={
                                            (moment().format('HH:mm') <= '07:00' && moment().format('HH:mm') >= '21:00') ||
                                            (data.possibilites_de_consultation_actuelles && 
                                                !data.possibilites_de_consultation_actuelles.find(i => i === 'visio'))
                                        }
                                        sx={{
                                            color: '#75ADBE',
                                        }}
                                        onClick={() => setOpenConfirmPopup({type: 'visio', code: data.code_perso_pro})}
                                    >
                                        <VideocamIcon />
                                    </IconButton>
                                </span>
                            </StyledTooltip>}
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
            <DescriptionPopup open={openDescriptionPopup} handleClose={() => setOpenDescriptionPopup(false)} />
            <ConfirmPopup
                open={openConfirmPopup}
                handleClose={() => setOpenConfirmPopup(false)}
                handleAccept={handleLaunchConsultation}
                title={`Vous vous apprêtez à lancer une consultation par ${openConfirmPopup.type === 'chat' ? 'Tchat' : 'Visio'}`}
            />
        </Paper>
    )
}