import * as React from 'react';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { getFavoris } from '../../Security/favorisStorage';
import { findIcone, FormatUrl } from '../../helper/utils';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ReactGA from 'react-ga';

export default function Favoris({ zoom, handleFavoris, navigateRoute, refreshFavoris, phone }) {
    document.title = 'Espace Salarié - Ressources'
    const TRACKING_ID = "G-RKY2WWVRSQ"
    ReactGA.initialize(TRACKING_ID)
    
    const handleNavigate = (favoris) => {
        ReactGA.event({
            category: 'favoris',
            action: 'Click sur une ressource en favoris',
            label: favoris.titre,
            value: 3
        })
        navigateRoute(`/ressources/${favoris.theme}/${FormatUrl(favoris.format)}/${favoris.id}`)
    }

    return (
        <div style={{maxWidth: 1500, margin: 'auto', textAlign: 'center', marginTop: 70}}>
            <Typography variant='h4' style={{color: '#75ADBE', marginBottom: 10}}>Mes Favoris <StarIcon color="secondary" fontSize="large" /></Typography>
            <Typography variant='body1' sx={{mb: 2}}>Lorsque vous aimez une ressource, vous pouvez l'enregistrer comme favori en cliquant sur l'étoile.<br /> Vous pourrez alors la retrouver facilement ici.</Typography>
            <Zoom in={zoom} timeout={700} style={{ transitionDelay: '600ms' }}>
                <div style={{maxWidth: 1200, display: phone ? 'block' : 'flex', justifyContent: 'center', margin: 'auto'}}>
                    {getFavoris() ? getFavoris().map((item, i) => {
                        return <div
                            key={item.id}
                            style={{
                                width: 300,
                                margin: phone ? 'auto' : '0px 30px 20px 0px',
                            }}
                            className='ressourceCard'
                        >
                            <Box
                                variant='contained'
                                color='secondary'
                                sx={{
                                    width: 300,
                                    mr: 5,
                                    height: 160,
                                    backgroundColor: '#ACCDD8',
                                    position: 'relative',
                                }}
                                onClick={() => handleNavigate(item)}
                            >
                                 <div
                                    style={{
                                        position: 'absolute',
                                        top: 30,
                                        left: 40,
                                        right: 20,
                                    }}
                                >
                                    <div style={{display: 'flex', flexFlow: 'column wrap', height: 40}}>
                                        <img src={findIcone(item.format)} alt={item.format} width={40} style={{zIndex: 9999}} />
                                        <Typography variant='body2' sx={{mt: 1.2, ml: 1, textAlign: 'right'}}>{item.duree_de_lecture} min</Typography>
                                    </div>
                                    <Typography color='primary' sx={{fontWeight: 'bold', mb: 3, mt: 2}}>{item.titre}</Typography>
                                </div>
                            </Box>
                            <Box
                                variant='contained'
                                color='secondary'
                                sx={{
                                    width: 300,
                                    mr: 5,
                                    height: 140,
                                    position: 'relative',
                                }}
                                onClick={() => handleNavigate(item)}
                            >
                                 <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 20,
                                        right: 20,
                                    }}
                                >
                                    <Typography color='primary' sx={{fontWeight: 'bold', mb: 3, mt: 2}}>{item.description}</Typography>
                                </div>
                            </Box>
                            <Box
                                variant='contained'
                                color='secondary'
                                sx={{
                                    width: 300,
                                    ml: 1,
                                    height: 'auto',
                                    position: 'relative',
                                }}
                            >
                                <div style={{display: 'flex'}}>
                                    {!refreshFavoris ? <IconButton onClick={() => handleFavoris(item)}>
                                        { getFavoris() && getFavoris().find((fav) => fav.id === item.id) ? <StarIcon color='secondary' /> : <StarOutlineIcon color='secondary' />}
                                    </IconButton> : <IconButton>
                                        <StarIcon color='secondary' />
                                    </IconButton>}
                                    <Typography variant='body2' sx={{mt: 1.2, ml: 1}}>Noté comme favori</Typography>
                                </div>
                            </Box>
                        </div>
                    }) : 
                    <Typography variant='body1' sx={{mb: 2}}>Pas de favoris...</Typography>}
                </div>
            </Zoom>
        </div>
    )
}