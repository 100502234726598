import React from "react";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import "./index.css";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import "survey-core/survey.i18n";
import { TransitionLeft } from "../../../../helper/utils";

StylesManager.applyTheme("defaultV2");
var defaultThemeColors = StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "#FFBDA6";
defaultThemeColors["$main-hover-color"] = "#DD8876B";
defaultThemeColors["$text-color"] = "#01304A";
defaultThemeColors["$header-color"] = "#FFBDA6";
defaultThemeColors["$header-background-color"] = "#01304A";
defaultThemeColors["$body-container-background-color"] = "#f8f8f8";
StylesManager.applyTheme();

function disableMultipleResponse(params) {
    var matrixValue = params[0];
    var rowCount = 0;
    if (matrixValue) {
      matrixValue.map(() => rowCount++)
    }
    return rowCount;
}
FunctionFactory.Instance.register("disableMultipleResponse", disableMultipleResponse);

export default function SurveyComponent({
    zoom,
    data,
    setOpenSnackbar,
    setText,
    setSeverity,
    setTransition,
    questions,
}) {
    const [survey] = React.useState(new Model(data));
    survey.locale = "fr";
    const [pageChangeCounter, setPageChangeCounter] = React.useState(0);
    const [hasValue, setHasValue] = React.useState(false);
    const [explainationText, setExplainationText] = React.useState(false);
    
    survey.onCurrentPageChanged.add((sender, options) => {
        setPageChangeCounter(pageChangeCounter + 1);
    });
    
    survey.onComplete.add((sender, options) => {
        setPageChangeCounter(pageChangeCounter + 1);
    });
    
    survey.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
    });
    const correctStr = "Bonne réponse";
    const incorrectStr = "Mauvaise réponse";
    
    function getTextHtml (text, str, isCorrect) {
        if (text.indexOf(str) < 0)
            return undefined;
    
        return text.substring(0, text.indexOf(str)) +
            "<span class='" +  (isCorrect ? "correctAnswer" : "incorrectAnswer" ) + "'>" +
                str +
            "</span>";
    }

    function isAnswerCorrect (q) {
        const correctAnswer = q.correctAnswer;
    
        if (!correctAnswer || q.isEmpty())
            return undefined;
    
        let givenAnswer = q.value;
        if (!Array.isArray(correctAnswer))
            return correctAnswer === givenAnswer;
    
        if (!Array.isArray(givenAnswer))
            givenAnswer = [givenAnswer];
    
        for (let i = 0; i < givenAnswer.length; i++) {
            if (correctAnswer.indexOf(givenAnswer[i]) < 0)
                return false;
        }
        return true;
    }

    function changeTitle (q) {
        if (!q) return;
    
        const isCorrect = isAnswerCorrect(q);
        if (!q.prevTitle) {
            q.prevTitle = q.title;
        }
        if (isCorrect === undefined) {
            q.title = q.prevTitle;
        }
        q.title =  q.prevTitle + ' ' + (isCorrect ? correctStr : incorrectStr);
    }

    survey.onValueChanged.add((_, options) => {
        if (options.question.isReadOnly) {
            findExplainationText(options)
            setHasValue(true)
            changeTitle(options.question);
        }
    });

    const findExplainationText = (options) => {
        const explaination = questions.find((question) => question.titre === options.name)
        if (explaination) setExplainationText(explaination.explication)        
    }

    survey.onTextMarkdown.add((_, options) => {
        const text = options.text;
        let html = getTextHtml(text, correctStr, true);
        if (!html) {
            html = getTextHtml(text, incorrectStr, false);
        }
        if (!!html) {
            options.html = html;
        }
    });
    
    const nextPageFunc = () => {
        setExplainationText(false);
        setHasValue(false)
        survey.nextPage();
    };
    
    const completePageFunc = () => { 
        setExplainationText(false);
        setHasValue(false)
        survey.completeLastPage(); 
    };

    const renderButton = (text, func, canRender) => {
        if (!canRender) return undefined;
        return (
          <button
            className="sd-btn sd-navigation__next-btn"
            style={{
              color: '#01304A',
              marginLeft: 50,
              marginBottom: 20
            }}
            onClick={() => {
              if (!hasValue) {
                setText('Veuillez choisir un réponse')
                setSeverity('warning')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                return
              }
              return func()
            }}>
              {text}
          </button>);
    };
    const renderExternalNavigation = () => {
        if(survey.state !== "running") return undefined;
        return <div className="navigation-block">
            {renderButton("Suivant", nextPageFunc, !survey.isLastPage)}
            {renderButton("Terminé", completePageFunc, survey.isLastPage)}
        </div>
    };

    const renderExplaination = () => {
        if (!explainationText) return undefined 
        return <div className="navigation-block">
                <Typography 
                    variant='body2'
                    color='primary'
                    sx={{
                        maxWidth: '600px',
                        margin: 'auto',
                        mt: -2.5,
                        fontStyle: 'italic',
                    }}
                >
                    {explainationText}
                </Typography>
            </div>
    }

    return (
      <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
        <Box
            sx={{
                maxWidth: 1300,
                margin: 'auto',
                position: 'relative',
                mb: 5
            }}
        >
            <Survey model={survey} />
            {renderExplaination()}
            {renderExternalNavigation()}
        </Box>
      </Zoom>
    );
} 
