import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography
} from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CloseVisioPopup({ open, handleClose, handleAccept }) {

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="alert-popup-description"
        >
                <DialogTitle sx={{fontWeight: 'bold', color: '#01304A', textAlign: 'center'}}>
                    Quitter la téléconsultation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-popup-description" component='div'>
                        <Typography style={{marginTop: 5}} variant='body1'>Voulez-vous mettre fin à la consultation ?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                    <Button onClick={handleAccept}>Accepter</Button>
                </DialogActions>
        </Dialog>
    );
}
