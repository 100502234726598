import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useParams } from 'react-router-dom';
import Zoom from '@mui/material/Zoom';
import { apiFetch } from '../../Security/apiFetch';
import { findIcone, findImg, FormatUrl, TransitionLeft, translateTitle } from '../../helper/utils';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import { getFavoris } from '../../Security/favorisStorage';
import { themes } from './listOfTheme';
import ReactGA from 'react-ga';

export default function Theme({
    setOpenSnackbar,
    setText,
    setSeverity,
    setTransition,
    setZoom,
    zoom,
    findPosition,
    setOpenBackdrop,
    navigateRoute,
    setBreadcrumb,
    setTheme,
    handleFavoris,
    refreshFavoris,
    phone,
}) {
    document.title = 'Espace Salarié - Ressources'
    const TRACKING_ID = "G-RKY2WWVRSQ"
    ReactGA.initialize(TRACKING_ID)

    const [ressources, setRessources] = React.useState([])
    const { id, type } = useParams()

    React.useEffect(() => {
        (async () => {
            setOpenBackdrop(true)
            setTheme(id)
            setBreadcrumb(['Ressources bien-être', id, type])
            const getRessources = await apiFetch(`/ressources?theme=${id}&format=${type}`)
            if (getRessources && getRessources.length) {
                setRessources(getRessources)
                setZoom(true)
            } else {
                setText('Erreur lors de la récupération du ressources !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                if (getRessources === '404') return navigateRoute('/error')
                navigateRoute('/')
                findPosition()
            }
            setOpenBackdrop(false)
        })()
        return () => setZoom(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleNavigate = (ressource) => {
        ReactGA.event({
            category: 'ressource',
            action: 'Click sur une ressource',
            label: ressource.titre,
            value: 1
        })
        navigateRoute(`/ressources/${id}/${FormatUrl(ressource.format)}/${ressource.id}`)
    }

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
            <div>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
                    <div style={{width: '100%', marginTop: phone ? 150 : 100, backgroundColor: '#EBF2F5', height: 250, display: 'table'}}>
                        <div style={{maxWidth: 1200, position: 'relative', margin: 'auto'}}>
                            <img src={findImg(id)} alt={id} width={230} style={{marginTop: -90}} />
                            <div
                                style={{
                                    position: phone ? 'relative' : 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    marginTop: phone ? 100 : 45,
                                    marginLeft: phone ? 10 : 0,
                                }}
                            >
                                <Typography
                                    variant='h4'
                                    color='primary'
                                >
                                    {translateTitle(id)}
                                </Typography>
                                <Typography>
                                    {themes.find(item => item.title === id).description}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Zoom>
                <Grid
                    container 
                    spacing={3}
                    style={{
                        maxWidth: 1300,
                        margin: 'auto',
                        justifyContent: 'center',
                        marginTop: 50
                    }}
                >
                    {ressources.map((ressource, index) => {
                        return <Grid item key={index} xs={12} sm={6} md={4} lg={4} xl={3}>
                            <div
                                key={index}
                                style={{
                                    width: 300,
                                    margin: phone ? 'auto' : '0px 30px 20px 0px',
                                }}
                                className='ressourceCard'
                            >
                                <Box
                                    variant='contained'
                                    color='secondary'
                                    sx={{
                                        width: 300,
                                        mr: {xl: 5},
                                        height: 160,
                                        backgroundColor: '#ACCDD8',
                                        position: 'relative',
                                    }}
                                    onClick={() => handleNavigate(ressource)}
                                >
                                     <div
                                        style={{
                                            position: 'absolute',
                                            top: 30,
                                            left: 40,
                                            right: 20,
                                        }}
                                    >   
                                        <div style={{display: 'flex', flexFlow: 'column wrap', height: 40}}>
                                            <img src={findIcone(ressource.format)} alt={ressource.format} width={40} style={{zIndex: 9999}} />
                                            <Typography variant='body2' sx={{mt: 1.2, ml: 1, textAlign: 'right'}}>{ressource.duree_de_lecture} min</Typography>
                                        </div>
                                        <Typography color='primary' sx={{fontWeight: 'bold', mb: 3, mt: 2}}>{ressource.titre}</Typography>
                                    </div>
                                </Box>
                                <Box
                                    variant='contained'
                                    color='secondary'
                                    sx={{
                                        width: 300,
                                        mr: 5,
                                        height: 140,
                                        position: 'relative',
                                    }}
                                    onClick={() => handleNavigate(ressource)}
                                >
                                     <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 20,
                                            right: 20,
                                        }}
                                    >
                                        <Typography
                                            color='primary'
                                            sx={{
                                                fontWeight: 'bold',
                                                mb: 3,
                                                mt: 2,
                                            }}
                                            className={'cutoff-text'}
                                        >
                                            {ressource.description}
                                        </Typography>
                                    </div>
                                </Box>
                                <Box
                                    variant='contained'
                                    color='secondary'
                                    sx={{
                                        width: 300,
                                        ml: 1,
                                        height: 'auto',
                                        position: 'relative',
                                    }}
                                >
                                    <div style={{display: 'flex'}}>
                                        {!refreshFavoris ? <IconButton onClick={() => handleFavoris(ressource)}>
                                            {getFavoris() && getFavoris().find((fav) => fav.id === ressource.id) ? <StarIcon color='secondary' /> : <StarOutlineIcon color='secondary' />}
                                        </IconButton> : <IconButton>
                                            <StarIcon color='secondary' />
                                        </IconButton>}
                                        <Typography variant='body2' sx={{mt: 1.2, ml: 1}}>Noté comme favori</Typography>
                                    </div>
                                </Box>
                            </div>
                            </Grid>
                    })}
                </Grid>
            </div>
        </Zoom>
    )
}