import React, { useEffect, useCallback, useState } from "react"
import moment from 'moment'
import 'moment/locale/fr';

moment().locale('fr')

const headHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13 ,14 ,15 ,16 ,17 ,18 ,19, 20, 21, 22, 23]

export default function Planning({ availability, isManagement }) {
    const [planningArray, setPlanningArray] = useState(false)

    const handleWeekArray = useCallback(() => {
        const days = []
        const dateStart = moment()
        const dateEnd = moment().add(6, 'days')
        const quarterHours = ["00", "30"];
        while (dateEnd.diff(dateStart, 'days') >= 0) {
            const times = [];
            const findDay = availability.filter((ava) => moment(ava.debut).format('ddd Do MMMM') === dateStart.format('ddd Do MMMM'))

            for(let i = 0; i < 24; i++){
                for(let j = 0; j < 2; j++){
                    let time = i + ":" + quarterHours[j];
                    if(i < 10){
                        time = "0" + time;
                    }

                    if (findDay.find((day) => time >= moment(day.debut).format('HH:mm') && time < format(moment(day.fin).format('HH:mm')))) {
                        times.push({time: time, booked: true});
                    } else {
                        times.push({time: time, booked: false});
                    }
                }
            }
            
            days.push({day: dateStart.format('ddd Do MMMM'), hours: times})
            dateStart.add(1, 'days')
        }

        setPlanningArray(days)
    }, [availability])

    useEffect(() => {

        handleWeekArray()

    }, [handleWeekArray])
    
    const format = (str) => {
        if (str === '00:00') {
            return '24:00'
        } else {
            return str
        }
    }
    return (
        <div>
            <table style={{borderSpacing: '0px 1px', margin: 'auto'}}>
                <thead>
                    <tr>
                        <th></th>
                        {headHours.map((i) => {
                            return <th key={i} colSpan="2" style={{textAlign: 'left', fontSize: 12}}>{i.toString().length === 1 && '0'}{i}h</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {planningArray && planningArray.map((day) => {
                            return <tr key={day.day}>
                                        <th key={day.day} style={{minWidth: 120, textAlign: 'right', fontSize: 15}}>{day.day} : </th>
                                        {
                                            day.hours.map((hour) => {
                                                if (hour.time.endsWith('30')) {
                                                    if (hour.booked) {
                                                        return <th key={hour.time} style={{width: 20, backgroundColor: isManagement ? '#01304A' : '#75ADBE', margin: 0, padding: 0}}></th>
                                                    }
                                                    return <th key={hour.time} style={{width: 20, backgroundColor: '#F3F3F3', margin: 0, padding: 0}}></th>
                                                } else {
                                                    if (hour.booked) {
                                                        return <th key={hour.time} style={{width: 20, backgroundColor: isManagement ? '#01304A' : '#75ADBE', borderLeft: 'thin #ddd solid', margin: 0, padding: 0}}></th>
                                                    }
                                                    return <th key={hour.time} style={{width: 20, backgroundColor: '#F3F3F3', borderLeft: 'thin #ddd solid', margin: 0, padding: 0}}></th>
                                                }

                                            })
                                        }
                            </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}