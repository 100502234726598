import "./wordl.css";
import Board from "./components/Board";
import Keyboard from "./components/Keyboard";
import { boardDefault, generateWordSet } from "./words";
import React, { useState, createContext, useEffect } from "react";
import GameOver from "./components/GameOver";
import { Typography, IconButton } from "@mui/material";
import InfoPopup from "./components/InfoPopup";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TransitionLeft } from "../../../../helper/utils";

export const AppContext = createContext();

function Wordl({ setOpenSnackbar, setTransition, setSeverity, setText, phone }) {
    const [board, setBoard] = useState(boardDefault);
    const [currAttempt, setCurrAttempt] = useState({ attempt: 0, letter: 0 });
    const [wordSet, setWordSet] = useState(new Set());
    const [correctWord, setCorrectWord] = useState("");
    const [openPopup, setOpenPopup] = useState(false);
    const [disabledLetters, setDisabledLetters] = useState([]);
    const [gameOver, setGameOver] = useState({
        gameOver: false,
        guessedWord: false,
    });

    useEffect(() => {
        setOpenPopup(true)
        generateWordSet().then((words) => {
            setWordSet(words.wordSet);
            setCorrectWord(words.todaysWord);
        });
    }, []);

    const onEnter = () => {
        if (currAttempt.letter !== 5) return;

        let currWord = "";
        for (let i = 0; i < 5; i++) {
            currWord += board[currAttempt.attempt][i];
        }

        if (wordSet.has(currWord)) {
            setCurrAttempt({ attempt: currAttempt.attempt + 1, letter: 0 });
        } else {
            setText("Ce mot n'est pas dans notre dictionnaire.")
            setSeverity('warning')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            return;
        }

        if (currWord === correctWord) {
            setGameOver({ gameOver: true, guessedWord: true });
            return;
        }

        if (currAttempt.attempt === 5) {
            setGameOver({ gameOver: true, guessedWord: false });
            return;
        }
    };

    const onDelete = () => {
        if (currAttempt.letter === 0) return;
        const newBoard = [...board];
        newBoard[currAttempt.attempt][currAttempt.letter - 1] = "";
        setBoard(newBoard);
        setCurrAttempt({ ...currAttempt, letter: currAttempt.letter - 1 });
        };
    
        const onSelectLetter = (key) => {
        if (currAttempt.letter > 4) return;
        const newBoard = [...board];
        newBoard[currAttempt.attempt][currAttempt.letter] = key;
        setBoard(newBoard);
        setCurrAttempt({
            attempt: currAttempt.attempt,
            letter: currAttempt.letter + 1,
        });
    };

    const reloadGame = () => {
        setBoard([
            ["", "", "", "", ""],
            ["", "", "", "", ""],
            ["", "", "", "", ""],
            ["", "", "", "", ""],
            ["", "", "", "", ""],
            ["", "", "", "", ""],
        ]);
        setCorrectWord("")
        setDisabledLetters([])
        setWordSet(new Set())
        setGameOver({
            gameOver: false,
            guessedWord: false,
        })
        setCurrAttempt({ attempt: 0, letter: 0 })
        generateWordSet().then((words) => {
            setWordSet(words.wordSet);
            setCorrectWord(words.todaysWord);
        })
    }

    return (
        <div className="App" style={phone ? {marginTop: -19, overflow: 'hidden'} :  {}}>
            <nav>
                <div style={{display: 'flex', width: '100%', margin: 'auto'}}>
                    <Typography variant='h3' style={{flex: '1 1 100%'}}>Wordle</Typography>
                    <IconButton color='secondary' onClick={() => setOpenPopup(true)}>
                        <InfoOutlinedIcon />
                    </IconButton>
                </div> 
            </nav>
            <div style={{maxWidth: 1300, margin: 'auto'}}>
                <Typography variant={phone ? 'body1' : 'h6'} style={{marginTop: 10}}>Cherchez un mot de 5 lettres sur le thème du bien-être</Typography>
            </div>
            <AppContext.Provider
                value={{
                    board,
                    currAttempt,
                    setCurrAttempt,
                    correctWord,
                    onSelectLetter,
                    onDelete,
                    onEnter,
                    setDisabledLetters,
                    disabledLetters,
                    gameOver,
                }}
            >
                <div className="game">
                    <Board />
                    {gameOver.gameOver ? <GameOver reload={reloadGame} /> : <Keyboard />}
                </div>
            </AppContext.Provider>
            <InfoPopup handleClose={() => setOpenPopup(false)} open={openPopup} />
        </div>
    );
}

export default Wordl;