import React, { useState, useEffect, useRef } from 'react';
import Video from 'twilio-video';
import {
    LinearProgress,
    Typography,
    Paper,
} from '@mui/material'
import Participant from './Participant.jsx';
import { TransitionLeft } from '../../helper/utils.js';
import bullBleu from '../../assets/home/bulle_droite_haut_gris.png'
import bullRose from '../../assets/home/bulle_gauche_bas_rose.png'
import ConfirmPopup from '../../components/ConfirmPopup.jsx';
import CloseVisioPopup from './CloseVisioPopup.jsx';
import avatar from '../../assets/No-avatar.png';
import NoAvatar from '../../assets/NoAvatar.png';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

const Room = ({
    setToken,
    roomConstraints,
    token,
    openClosePopup,
    setTransition,
    setOpenSnackbar,
    setOpenClosePopup,
    setSeverity,
    setText,
    psyIdentity,
    setZoom,
    navigateRoute,
    setDisableMenu,
    stream,
    location,
}) => {
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [openConfirmPopup, setOpenConfirmPopup] = useState(false)
    const [remoteDisconnected, setRemoteDisconnected] = useState(false)
    const [remoteMic, setRemoteMic] = useState(true);
    const [remoteCam, setRemoteCam] = useState(true);
    const remoteParticipantRef = useRef();
    const remoteMicRef = useRef();

    useEffect(() => {
        const participantConnected = participant => {
            if (participant.state === 'connected') {
                setParticipants(() => {
                    return [participant]
                });
            }
        };
      
        const participantDisconnected = participant => {
            setRemoteDisconnected(true)
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };
        const body = roomConstraints.length ? {
            tracks: roomConstraints
        } : {}
        Video.connect(token, body).then(room => {
            setDisableMenu(true)
            setZoom(true)
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        }).catch((e) => {
            setDisableMenu(false)
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setText('Erreur lors du lancement de la vidéo, veulliez verifier votre caméra et/ou micro')
            console.warn(e)
            setRoom(null)
            handleClose()
        });
      
        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
            handleCloseLocalMedia()
            setDisableMenu(false)
            window.removeEventListener("participantConnected", participantConnected);
            window.removeEventListener("participantDisconnected", participantDisconnected);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseLocalMedia = () => {
        if (!stream) return;
        if (stream?.getVideoTracks) {
            stream.getVideoTracks().map(track => track.stop());
        }
        if (stream?.getAudioTracks) {
            stream.getAudioTracks().map(track => track.stop());
        }
        if (stream?.stop) {
            stream.stop();
        }
    }

    const handleCloseVisio = () => {
        setOpenConfirmPopup(false)
    }

    const handleClose = async () => {
        if (room && room.localParticipant) {
            await room.localParticipant.tracks.forEach(async function(trackPublication) {
                await trackPublication.track.stop();
            });
        }
        room.disconnect();
        setToken(null)
        setDisableMenu(false)
        if (location.state && location.state === 'isFromLogin') {
            return navigateRoute('/psy')
        }
        navigateRoute(-1)
    }

    return (
        <>
            {room ? <div>
                <img
                    src={bullBleu}
                    alt='Visioconsultation Pros Consulte'
                    width={800}
                    style={{position: 'absolute', right: 0, top: 0, zIndex: -9999}}
                />
                <div>
                    <Typography
                        variant='h4' 
                        sx={{
                            textAlign: 'center',
                            color: '#75ADBE',
                        }}
                    >
                        Bienvenue dans la Visio
                    </Typography>
                    <div style={{display: 'flex', maxWidth: 1500, margin: 'auto'}}>
                            <Paper
                                sx={{
                                    borderBottomLeftRadius: 30,
                                    borderTopLeftRadius: 30,
                                    boxShadow: '-12px 9px 17px #00000029',
                                    mt: 2.2,
                                    width: '75%',
                                }}
                                id="visio"
                            >

                                {!!participants.length ? participants.map(participant => {
                                    return <Participant
                                        room={room}
                                        setToken={setToken}
                                        setOpenClosePopup={setOpenClosePopup}
                                        key={participant.sid}
                                        participant={participant}
                                        psyIdentity={psyIdentity}
                                        setRemoteMic={setRemoteMic}
                                        setRemoteCam={setRemoteCam}
                                        remoteParticipantStyle={remoteParticipantRef}
                                        remoteMicStyle={remoteMicRef}
                                        remoteCam={remoteCam}
                                    />
                                }) : !remoteDisconnected ? 
                                <div
                                    style={{
                                        backgroundColor: '#323232',
                                        color: 'white',
                                        borderTopLeftRadius: 30,
                                        borderBottomLeftRadius: 30,
                                        textAlign: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <img src={avatar} alt='avatar' width={160} style={{marginTop: 120}} />
                                    <Typography sx={{textAlign: 'center'}}>
                                        Veuillez patienter votre interlocuteur va rejoindre la visioconsultation
                                    </Typography>
                                </div> : <div
                                    style={{
                                        backgroundColor: '#323232',
                                        color: 'white',
                                        borderTopLeftRadius: 30,
                                        borderBottomLeftRadius: 30,
                                        textAlign: 'center',
                                        position: 'relative',
                                        height: '100%',
                                    }}
                                >
                                    <img
                                        src={NoAvatar}
                                        alt='Psychologue deconnecte'
                                        width={325}
                                        style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
                                    />
                                </div>}
                            </Paper>
                            <Paper
                                sx={{
                                    marginRight: 10,
                                    borderTopRightRadius: 30,
                                    borderBottomRightRadius: 30,
                                    boxShadow: '-12px 9px 17px #00000029',
                                    marginTop: 2.2,
                                    width: '25%',
                                }}
                            >
                                {room && (
                                    <React.Fragment>
                                        <Participant
                                            key={room.localParticipant.sid}
                                            participant={room.localParticipant}
                                            isLocal={true}
                                            setToken={setToken}
                                            setOpenClosePopup={setOpenClosePopup}
                                            blackScreenIsDisplayed={participants.length === 0}
                                            room={room}
                                            psyIdentity={psyIdentity}
                                            setRemoteCam={setRemoteCam}
                                            remoteCam={remoteCam}
                                        />
                                        <div
                                            ref={remoteParticipantRef}
                                            style={{
                                                backgroundColor: '#323232',
                                                textAlign: 'center',
                                                color: 'white',
                                            }}
                                            id='remoteParticipant'
                                        >
                                            <img src={remoteDisconnected ? NoAvatar : avatar} alt='avatar' width={160} style={{marginTop: 120}} />
                                            <Typography>{psyIdentity}</Typography>
                                        </div>
                                        <div
                                            ref={remoteMicRef}
                                            style={{
                                                textAlign: 'center',
                                                paddingBottom: 32,
                                                paddingTop: 40,
                                                backgroundColor: '#323232',
                                                borderBottomRightRadius: 30,
                                                color: 'white'
                                            }}
                                            id='remoteMic'
                                        >
                                            {remoteMic && !!participants.length ? <MicIcon /> : <MicOffIcon />}
                                        </div>
                                    </React.Fragment>
                                )}
                            </Paper>
                    </div>
                </div>
                <CloseVisioPopup handleClose={() => setOpenClosePopup(false)} handleAccept={handleClose} open={openClosePopup} />
                <img
                    src={bullRose}
                    alt='Visioconsultation Pros Consulte'
                    width={350}
                    style={{position: 'absolute', left: 0, bottom: 50, zIndex: -9999}}
                />
                <ConfirmPopup
                    open={openConfirmPopup}
                    handleAccept={handleCloseVisio}
                    handleClose={() => setOpenConfirmPopup(false)}
                    title='Vous vous apprêter à quitter la visio !'
                />
            </div> : <LinearProgress />}
        </>
    );
};

export default Room;
