import React, { useEffect, useState } from 'react'
import { Client as ConversationClient} from "@twilio/conversations";
import { apiFetch } from '../Security/apiFetch';
import { useParams } from 'react-router-dom';
import { getCode, getNum } from '../Security/Authentifier';
import { TransitionLeft } from '../helper/utils';
import {
    Zoom,
    Paper,
    Typography,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    FilledInput,
} from '@mui/material'
import { Send } from '@mui/icons-material';
import bullBleu from '../assets/home/bulle_droite_haut_gris.png'
import bullRose from '../assets/home/bulle_gauche_bas_rose.png'
import Messages from './ChatItem/Messages';
import ConfirmPopup from '../components/ConfirmPopup';

export default function Chat({
    setOpenSnackbar,
    setText,
    setSeverity,
    setTransition,
    setZoom,
    zoom,
    navigateRoute,
    setOpenBackdrop,
    setDisableMenu,
}) {
    const { id } = useParams()
    const [psyData, setPsyData] = useState(false)
    const [user, setUser] = useState(false)
    const [messages, setMessages] = useState([])
    const [newConversation, setNewConversation] = useState(false)
    const [isTyping, setIsTyping] = useState(false)
    const [openConfirmPopup, setOpenConfirmPopup] = useState(false)
    const [newMessage, setNewMessage] = useState('')

    useEffect(() => {
        (async () => {
            setOpenBackdrop(true)
            const body = new URLSearchParams();
            body.append('numero_vert', getNum())

            const checkNum = await apiFetch('/espace-salarie/authentifier', {
                method: 'POST',
                body: body

            })
            body.append('code_pro', id)

            if (checkNum.error === 'Code entreprise requis.') body.append('code_groupe', getCode())

            const getChatAccessibility = await apiFetch('/plateforme/tchat/demarrer-une-consultation-twilio', {
                method: 'POST',
                body: body
            })

            const getPsy = await apiFetch(`/pros/${id}`)
            if (getPsy && getPsy.success) {
                setPsyData(getPsy.data)
            } else {
                setText('Désolé, une erreur s\'est produite, veuillez réessayer !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigateRoute(-1)
            }

            setOpenBackdrop(false)
            setZoom(true)
            
            if (getChatAccessibility.conversation_sid) {
                setDisableMenu(true)
                const client = new ConversationClient(getChatAccessibility.token_utilisateur)
                client.on('messageAdded', (message) => {
                    handleMessageAdded(message)
                    scrollDown();
                });
        
                // Une conversation a été ajoutée, cet événement est aussi trigger à l'initialisation.
                client.on('conversationAdded', (conversation) => {
        
                    setNewConversation(conversation);
                    
                    conversation.on('typingStarted', (participant) => {
                        updateTypingIndicator(participant, true)
                    });
                    conversation.on('typingEnded', (participant) => {
                        updateTypingIndicator(participant, false)
                    });
        
                    conversation.getParticipants().then((participants) => {
                        const findUser = participants.find(participant => participant.identity === client.user.identity);
                        setUser(findUser);
                    })
        
                    conversation.getMessages().then((messages) => {
                        messages.items.map((message) => handleMessageAdded(message))
                        scrollDown();
                    })
                });
        
                client.on('conversationRemoved', () => {
                    setText('la consultation est clôturée, vous allez être rediriger vers la liste des psychologues')
                    setTransition(() => TransitionLeft)
                    setSeverity('warning')
                    setDisableMenu(false)
                    setOpenSnackbar(true)
                    setTimeout(() => {
                        navigateRoute('/psy')
                    }, 2000)
                });
            } else {
                setText(getChatAccessibility)
                setTransition(() => TransitionLeft)
                setDisableMenu(false)
                setSeverity('error')
                setOpenSnackbar(true)
                navigateRoute(-1)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateTypingIndicator = (participant, indicator) => {
        setIsTyping({participant: participant, indicator: indicator})
    }

    const handleMessageAdded = (message) => {
            setMessages(oldMessage => [...oldMessage, message]);
    }

    const scrollDown = () => {
        const paper = document.getElementById('messages')
        setTimeout(() => {
            paper.scrollTop = paper.scrollHeight
        }, 50)
    }

    const leaveConversation = () => {
        setOpenConfirmPopup(true)
    }

    const handleCloseChat = () => {
        setOpenConfirmPopup(false)
        setDisableMenu(false)
        user.remove()
    }

    const handleChangeMessage = (e) => {
        setNewMessage(e.target.value)
        newConversation.typing()
    }

    const handleSendMessage = async (e, force) => {
        if (newConversation) {
            if (e.keyCode === 13 || force) {
                newConversation.sendMessage(newMessage);
                scrollDown()
                setNewMessage('')
            }
        }
    }

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
            <div>
                <img src={bullBleu} alt='Psychologue Pros Consulte' width={800} style={{position: 'absolute', right: 0, top: 0, zIndex: -9999}}/>
                <div style={{textAlign: 'right'}}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={leaveConversation}
                        sx={{
                            mr: 4,
                            mt: 2,
                        }}
                    >
                        Quitter la consultation
                    </Button>
                </div>
                <div>
                    <Typography
                        variant='h4' 
                        sx={{
                            textAlign: 'center',
                            color: '#75ADBE',
                        }}
                    >
                        Bienvenue dans le Tchat
                    </Typography>
                    <Paper
                        style={{
                            width: 1220,
                            overflow: 'scroll',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            boxShadow: '-12px 9px 17px #00000029',
                            padding: '20px 40px',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            bottom: -100,
                            transform: 'translate(-50%, -50%)',
                        }}
                        id='messages'
                    >
                        {messages.length ? messages.map((message, i)=> {
                            return <Messages key={i} message={message} user={user} psyData={psyData} />
                        }) : 
                        psyData && <Typography sx={{textAlign: 'center'}} variant='body1'>Bienvenue dans cette conversation avec {psyData.pseudo}</Typography>}
                    </Paper>
                    <Paper
                        sx={{
                            backgroundColor: '#F2F2F2',
                            height: 100,
                            width: 1300,
                            margin: 'auto',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            boxShadow: '-12px 9px 17px #00000029',
                            position: 'absolute',
                            top: '86%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        {isTyping.indicator && <div style={{fontSize: 12, marginLeft: 45, zIndex: 99999}}>
                            <em>{isTyping.participant.identity.split('|')[0]} est en train d'écrire</em>
                        </div>}
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl sx={{width: '1220px', position: 'fixed', bottom: 20}} variant="outlined">
                                <InputLabel htmlFor="message">Message</InputLabel>
                                <FilledInput
                                    autoComplete={'off'}
                                    autoFocus
                                    id="message"
                                    value={newMessage}
                                    onChange={handleChangeMessage}
                                    onKeyDown={handleSendMessage}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => handleSendMessage(e, true)}
                                                edge="end"
                                            >
                                                <Send />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Message"
                                />
                            </FormControl>
                        </div>
                    </Paper>
                </div>
                <img src={bullRose} alt='Psychologue Pros Consulte' width={350} style={{position: 'absolute', left: 0, bottom: 50, zIndex: -9999}}/>
                <ConfirmPopup
                    open={openConfirmPopup}
                    handleAccept={handleCloseChat}
                    handleClose={() => setOpenConfirmPopup(false)}
                    title='Vous vous apprêtez à quitter le Tchat !'
                />
            </div>
        </Zoom>
    )
}