import React, { useEffect, useState } from 'react'
import { Typography, TextField, InputAdornment, IconButton, Button } from "@mui/material";
import { Visibility, VisibilityOff, LockOpen } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { TransitionLeft } from '../helper/utils';
import LoadingButton from '@mui/lab/LoadingButton';
import AlertSnackbar from '../components/AlertSnackbar';
import { apiFetch } from '../Security/apiFetch';
import logo from '../assets/logo.jpg'
import { getAuth } from '../Security/Authentifier';

export default function Login({ setCanGoBack }) {
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false)
    const [fieldError, setFieldError] = useState({})
    const [loading, setLoading] = useState(false)
    const [transition, setTransition] = useState(false)
    const [severity, setSeverity] = useState(false)
    const [hasCode, setHasCode] = useState(false)
    const [message, setMessage] = useState(false)
    let navigate = useNavigate()

    document.title = 'Espace Salarié - Connexion'

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = async (e) => {
        setLoading(true)
        setError(false)
        setFieldError({})
        e.preventDefault()
        let data = new FormData(e.target)
        const body = new URLSearchParams();
        const password = data.get('password')

        body.append('numero_vert', data.get('numeroVert') || getAuth().num)
        if (data.get('code') || (getAuth() && getAuth().code)) body.append('code_entreprise', data.get('code') || getAuth().code)
        body.append('mot_de_passe_entreprise', password)

        try {
            const login = await apiFetch('/espace-salarie/authentifier', {
                method: 'POST',
                body: body
            })

            if (login.numero_vert_valide && !password) {
                localStorage.setItem('AUTHENTIFIER', JSON.stringify({num: data.get('numeroVert'), code: data.get('code')}))
                localStorage.setItem('NUM', JSON.stringify(data.get('numeroVert')))
                
                if (data.get('code') || (getAuth() && getAuth().code)) localStorage.setItem('CODE', JSON.stringify(data.get('code')))
                
                JSON.parse(localStorage.getItem('LAST_URL')) ? 
                    navigate(`${JSON.parse(localStorage.getItem('LAST_URL'))}`, {state: 'isFromLogin'}) : navigate('/') // get last route
            } else if (login.numero_vert_valide && password && login.mot_de_passe_entreprise_valide) {
                localStorage.setItem('AUTHENTIFIER', JSON.stringify('full'))
                localStorage.setItem('NUM', JSON.stringify(data.get('numeroVert')))

                if (data.get('code') || (getAuth() && getAuth().code)) localStorage.setItem('CODE', JSON.stringify(data.get('code')))
                
                JSON.parse(localStorage.getItem('LAST_URL')) ? 
                    navigate(`${JSON.parse(localStorage.getItem('LAST_URL'))}`, {state: 'isFromLogin'}) : navigate('/') // get last route
            } else {
                
                if (!login.mot_de_passe_entreprise_valide && password) setFieldError({password: 'Le mot de passe n\'est pas valide'})
                if (login.error === 'Code entreprise invalide.') setFieldError(prevState => ({...prevState, code: 'Code entreprise invalide.'}))
                if (login === 'Numéro vert malformé.') setFieldError(prevState => ({...prevState, numeroVert: 'Vous avez utilisé un mauvais numéro, veuillez réessayer !'}))
                if (login.error === 'Numéro vert invalide.') setFieldError(prevState => ({...prevState, numeroVert: 'Vous avez utilisé un mauvais numéro, veuillez réessayer !'}))
                if (login.error === 'Numéro vert obligatoire.') setFieldError(prevState => ({...prevState, numeroVert: 'Numéro vert obligatoire, veuillez réessayer !'}))
                if (login.error === 'Code entreprise requis.') setFieldError(prevState => ({...prevState, code: 'Code entreprise requis !'}))
                
                setMessage('Erreur lors de l\'authentification')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setError(true)
            }
        } catch (e) {
            setMessage('Désolé, une erreur ses produites lors de la connexion, veuillez réessayer !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setError(true)
        }
        setLoading(false)
    }

    const checkNum = async (e) => {
        if (e.target.value.length === 10) {
            try {
                const body = new URLSearchParams();
                body.append('numero_vert', e.target.value)
                const login = await apiFetch('/espace-salarie/authentifier', {
                    method: 'POST',
                    body: body
                })

                if (login.error === 'Code entreprise requis.') return setHasCode(true)
                if (login.error === 'Numéro vert malformé.') {
                    setMessage('Erreur lors de l\'authentification !')
                    setFieldError({numeroVert: 'Vous avez utilisé un mauvais numéro, veuillez réessayer !'})
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setError(true)
                }
            } catch (e) {
                setMessage(e)
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setError(true)
                setLoading(false)
            }
        } else {
            setHasCode(false)
        }
        setFieldError({})
    }

    const handleContinue = () => {
        navigate(`${JSON.parse(localStorage.getItem('LAST_URL'))}`, {state: 'isFromLogin'})
    }
    
    useEffect(() => {
        setCanGoBack(true)
        return () => setCanGoBack(false)
    })

    return (
        <div 
            className='login'
        >
            <div
                style={{
                    maxWidth: 380,
                    width: '100%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <div style={{padding: 20, borderRadius: '10px', backgroundColor: 'white', }}>
                    <div style={{width: '100%', margin: 'auto', textAlign: 'center'}}>
                        <img src={logo} alt="Pros consulte" width={100} height={100} />
                    </div>
                    <Typography 
                        variant='h5' 
                        color={'primary'} 
                        style={{
                            fontSize: 22,
                            fontWeight: 'bold',
                            letterSpacing: 1,
                            marginBottom: 15
                        }}
                    >
                        Bienvenue sur votre espace ! 
                    </Typography>
                    <Typography variant='h6' color={'primary'} style={{fontSize: 15}}>
                        Connectez-vous à votre espace à l'aide du numéro vert et du mot de passe entreprise qui vous a été communiqués.
                    </Typography>
                    <form noValidate onSubmit={handleSubmit} autoComplete="on">
                        {(!getAuth() || (getAuth() && !getAuth().num)) && <TextField
                            inputProps={{ maxLength: 10 }}
                            onChange={checkNum}
                            color={'primary'}
                            autoComplete='numeroVert'
                            id="numeroVert"
                            name="numeroVert"
                            label="Numéro Vert *"
                            sx={{width: '100%', marginTop: 3.75}}
                            error={!!fieldError.numeroVert}
                            helperText={fieldError.numeroVert}
                        />}
                        {hasCode && <TextField
                            color={'primary'}
                            id="code" name="code"
                            label="Code entreprise*"
                            sx={{width: '100%', marginTop: 3.75}}
                            error={!!fieldError.code}
                            helperText={fieldError.code}
                        />}<br />
                        <TextField 
                            id="password" 
                            color={'primary'}
                            name="password" 
                            label="Mot de passe entreprise"
                            sx={{width: '100%', marginTop: 3}}
                            type={showPassword ? "text" : "password"}
                            autoComplete='current-password'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={!!fieldError.password}
                            helperText={fieldError.password}
                        /><br />
                        <Typography variant="captions" sx={{fontSize: 11, color: 'grey'}}>
                            Le mot de passe entreprise n'est pas obligatoire, il sert uniquement pour consulter les conseils bien-ếtre
                        </Typography>
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color={'primary'}
                            sx={{marginTop: 5, width: '100%', height: 50, marginBottom: 2}}
                            endIcon={<LockOpen />}
                        >
                            Se connecter
                        </LoadingButton>
                        <Button
                            variant='contained'
                            color='secondary'
                            sx={{width: '100%', height: 50}}
                            onClick={handleContinue}
                        >
                            {!getAuth() ? "Continuer en tant qu'invité" : 'Retour'}
                        </Button>
                    </form>
                </div>
            </div>
            <AlertSnackbar open={!!error} setOpen={setError} transition={transition} message={message} severity={severity} />
        </div>
    )
}