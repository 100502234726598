import React, { useState } from 'react'
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import VideoPlayer from '../../components/VideoPlayer';
import videoImg from '../../assets/home/video_acceuil.png'
import bullBleu from '../../assets/home/bulle_droite_haut_gris.png'
import bullRose from '../../assets/home/bulle_gauche_bas_rose.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function About({ zoom, setZoom, navigateRoute, phone, tablet }) {
    const [isPlaying, setIsPlaying] = useState(false)

    React.useEffect(() => {
        setZoom(true)
        return () => setZoom(false)
    }, [setZoom])

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} sx={{mt: {xl: 38, xs: 15}, width: '100%'}}>
                    <img
                        src={bullRose}
                        alt='Bienvenue dans votre espace pros consulte'
                        width={phone ? '100%' : 500}
                        style={{marginLeft: 0, marginTop: 20}}
                    />
                    <Box
                        sx={{
                            width: {xl: 800, xs: '100%'},
                            mt: -65, 
                            ml: {xl: 10, xs: 0}
                        }}
                    >
                        {!isPlaying ?
                            <img
                                src={videoImg}
                                alt='écoute bienveillante'
                                onClick={() => setIsPlaying(true)}
                                width={phone ? '100%' : 800}
                                style={{borderRadius: 8}}
                            /> :
                            <VideoPlayer autoPlay={false} videoUrl={'https://bo-public-prod.s3.eu-west-3.amazonaws.com/communication/autres/Pros-Consulte_Video_Decouvrir_APPLI+PC.mp4'} />}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={1} sx={{mt: {xl: 19.4, xs: 18}, textAlign: 'justify'}}>
                        <Grid item xs={12} sm={8} sx={{paddingRight: 5, ml: {xl: 0, xs: 2}}}>
                            {!phone && <img src={bullBleu} alt='Bienvenue dans votre espace pros consulte' width={220} style={{marginLeft: 40}}/>}
                            <Typography color='primary' sx={{fontWeight: 'bold', mt: -18}} variant='h4'>Bienvenue</Typography>
                            <Typography color='primary' sx={{fontWeight: 'bold', mt: 1}} variant='h4'>dans votre espace !</Typography>
                            <Typography color='primary' variant='h5' sx={{mt: 8}} >DES PSYCHOLOGUES À VOTRE ÉCOUTE</Typography>
                            <Typography color='primary' variant='body1' sx={{mt: 2}}>Vous rencontrez des difficultés dans votre vie professionnelle ou personnelle, et avez besoin de vous confier à un professionnel de l’écoute. Vous pouvez contacter gratuitement un psychologue diplômé, qui vous apportera du soutien et des conseils, pour vous aider à surmonter vos problèmes et trouver les ressources pour faire face. C’est vous qui choisissez le ou la psychologue qui pourra vous accompagner sur plusieurs séances.</Typography>
                            {phone && <Button color='primary' onClick={() => navigateRoute('/psy')} sx={{mt: 1, ml: 2}} endIcon={<ArrowForwardIosIcon />}>En savoir plus</Button>}
                            <Typography color='primary' variant='h5' sx={{mt: 2}}>SOUTIEN AUX MANAGERS</Typography>
                            <Typography color='primary' variant='body1' sx={{mt: 2}}>Vous êtes manager ? Vous pouvez bénéficier d’un échange direct avec un consultant en management, qui vous aidera à appréhender différentes situations : conflit dans l’équipe, posture managériale, ou toute autre problématique ponctuelle que vous pourriez rencontrer dans le cadre de votre rôle de manager. Nos psychologues du travail et coachs sont tous diplômés.</Typography>
                            {phone && <Button color='primary' onClick={() => navigateRoute('/management')} sx={{mt: 1, ml: 2}} endIcon={<ArrowForwardIosIcon />}>En savoir plus</Button>}
                            <Typography color='primary' variant='h5' sx={{mt: 2}}>CONSEILS BIEN-ÊTRE</Typography>
                            <Typography color='primary' variant='body1' sx={{mt: 2}}>Favorisez votre bien-être et protégez votre santé grâce à nos experts. Explorez librement de nombreux contenus qui vous aideront à prendre soin de vous au quotidien.</Typography>
                            {phone && <Button color='primary' onClick={() => navigateRoute('/ressources')} sx={{mt: 1, ml: 2}} endIcon={<ArrowForwardIosIcon />}>En savoir plus</Button>}
                        </Grid>
                        {!phone && <Grid item xs={12} sm={2}>
                            <Button
                                color='primary'
                                onClick={() => navigateRoute('/psy')}
                                sx={{mt: 43}}
                                endIcon={
                                    <ArrowForwardIosIcon />
                                }
                            >
                                En savoir plus
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => navigateRoute('/management')}
                                sx={{mt: 20}}
                                endIcon={
                                    <ArrowForwardIosIcon />
                                }
                            >
                                En savoir plus
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => navigateRoute('/ressources')}
                                sx={{mt: 17}}
                                endIcon={
                                    <ArrowForwardIosIcon />
                                }
                            >
                                En savoir plus
                            </Button>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </Zoom>
    )
}