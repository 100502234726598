import React from 'react'
import bandeau from '../assets/home/bandeau_home.jpg'
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import About from './HomeItem/About';
import Reasons from './HomeItem/Reasons';
import Testimonials from './HomeItem/Testimonials';
import ReactGA from 'react-ga';


export default function Home({ zoom, setZoom, navigateRoute, phone, setBreadcrumb, tablet }) {
    document.title = 'Espace Salarié'
    const TRACKING_ID = "G-RKY2WWVRSQ"
    ReactGA.initialize(TRACKING_ID)
    
    React.useEffect(() => {
        setZoom(true)
        ReactGA.pageview(window.location.pathname)
        setBreadcrumb([])
        return () => setZoom(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setZoom])

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
            <div>
                <Box style={{marginTop: phone ? -19 : -61}}>
                    <img src={bandeau} alt='Prenez soin de vous' style={{width: '100%'}} />
                </Box>
                {phone || tablet ? <Box 
                    sx={{
                        marginTop: tablet ? -22 : -12,
                        zIndex: 9999999,
                        ml: 2,
                        color: 'white',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: tablet ? 25 : 15,
                            fontWeight: 'bold',
                            mb: 0.5
                        }}
                    >
                        Prenez soin de vous
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: tablet ? 22 : 14,
                            fontWeight: 'normal'
                        }}
                    >
                        Accédez à des contenus bien-être
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: tablet ? 22 : 14,
                            fontWeight: 'normal'
                        }}
                    >
                        et à des consultations gratuites avec des professionnels.
                    </Typography>
                </Box> : 
                <Box 
                    sx={{
                        marginTop: -39,
                        zIndex: 9999999,
                        ml: {xl: 25},
                        color: 'white',
                    }}
                >
                    <Typography sx={{fontWeight: 'bold', mb: 3}} variant='h3'>Prenez soin de vous</Typography>
                    <Typography sx={{fontWeight: 'normal'}} variant='h5'>Accédez à des contenus bien-être</Typography>
                    <Typography sx={{fontWeight: 'normal'}} variant='h5'>et à des consultations gratuites avec des professionnels.</Typography>
                </Box>}
                <About zoom={zoom} setZoom={setZoom} navigateRoute={navigateRoute} phone={phone} tablet={tablet} />
                <Reasons zoom={zoom} setZoom={setZoom} phone={phone} />
                <Testimonials zoom={zoom} setZoom={setZoom} />
            </div>
        </Zoom>
    )
}